import React, { useRef, useEffect, useState } from "react";
import { Input, Modal, Button, Spin } from "antd";
import { TranslateLanguage } from "../../Adapters/translationAPICalls";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { predefinedLayouts } from "../../constants";
const MultiOutputTranslation = ({
    language,
    varInput,
    placeHolder,
    variable,
    changeFlag,
    setTranslatedDataObj,
    isTemplateValue,
}) => {
    const [translatedText, setTranslatedText] = useState("");
    const [keyboardInput, setKeyboardInput] = useState(varInput);
    const [keyboardLayout, setKeyboardLayout] = useState(null);
    const [showLanguageEditor, setShowLanguageEditor] = useState(false);
    const [keyBoardLanguage, setKeyBoardLanguage] = useState("english"); // Default language
    const [shiftActive, setShiftActive] = useState(false);
    const keyboardInstance = useRef(null);
    const handleSave = () => {
        setShowLanguageEditor(false);
        setTranslatedText(keyboardInput);
    };

    const fallbackLayout = predefinedLayouts.english; // Use English as fallback

    useEffect(() => {
        if (!keyBoardLanguage) {
            console.error("Language is undefined");
            return;
        }
        const layout = predefinedLayouts[keyBoardLanguage] || fallbackLayout;
        setKeyboardLayout(layout);
    }, [keyBoardLanguage]);

    const onKeyboardChange = (input) => {
        setKeyboardInput(input);
    };

    const onEditRequestHandler = (item, language) => {
        setKeyBoardLanguage(String(language).toLowerCase());
        setKeyboardInput(item);
        if (keyboardInstance.current) {
            keyboardInstance.current.setInput(item);
        }
    };

    useEffect(() => {
        if (translatedText?.length > 0) {
            setTranslatedDataObj((prev) => ({
                ...prev,
                [variable]: {
                    ...prev[variable],
                    [language]: translatedText,
                },
            }));
        }
    }, [translatedText]);

    useEffect(() => {
        setTranslatedText("");
        setTranslatedDataObj({});
    }, [isTemplateValue]);

    useEffect(() => {
        keyboardInstance.current?.setInput(keyboardInput);
    }, [keyboardInstance.current]);

    useEffect(() => {
        translateVariableData();
    }, [changeFlag, language]);

    const languageMappings = {
        english: "en",
        hindi: "hi",
        kannada: "kn",
        punjabi: "pa",
        telugu: "te",
        tamil: "ta",
        oriya: "or",
        odia: "or",
        bangla: "bn",
    };

    const translateVariableData = async () => {
        // checking the language and then running the api calll
        let ln;
        const lowercaseLanguage = String(language).toLowerCase();

        if (lowercaseLanguage === "english") {
            // no need of API call if the language is English
            setTranslatedText(varInput);
            ln = "en";
            return;
        } else if (languageMappings.hasOwnProperty(lowercaseLanguage)) {
            ln = languageMappings[lowercaseLanguage];
        } else {
            // Handle unsupported languages or set a default language code
            ln = "default"; // Replace with your default language code or handle accordingly
        }

        try {
            if (varInput?.trim()) {
                var res = await TranslateLanguage(varInput, "en", ln);
                setTranslatedText(
                    res?.data?.data?.translations[0].translatedText
                );
            }
        } catch (error) {
            console.warn(error);
        }
    };
    const handleShift = () => {
        setShiftActive((prevShiftActive) => {
            const newShiftActive = !prevShiftActive;
            const newLayout = newShiftActive ? "shift" : "default";
            setKeyboardLayout((prev) => ({
                ...prev,
                [newLayout]: predefinedLayouts[keyBoardLanguage]?.[newLayout], // Switch layout
            }));
            return newShiftActive;
        });
    };
    const onKeyPress = (button) => {
        if (button === "{shift}") {
            handleShift(); // Toggle shift layout on press
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <Input.TextArea
                placeholder={`Translation in ${placeHolder}`}
                value={translatedText}
                showCount
                readOnly
                rows={4}
                cols={50}
                maxLength={30}
            />

            {language !== "English" && (
                <img
                    onClick={() => {
                        setShowLanguageEditor(true);
                        onEditRequestHandler(translatedText, language);
                    }}
                    src="/assets/images/ButtonLogos/keyboard.svg"
                    style={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        zIndex: 1,
                        cursor: "pointer",
                    }}
                />
            )}
            <Modal
                title="Language Editor"
                open={showLanguageEditor}
                onCancel={() => setShowLanguageEditor(false)}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => setShowLanguageEditor(false)}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save
                    </Button>,
                ]}>
                <textarea
                    value={keyboardInput}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                        setKeyboardInput(e.target.value);
                    }}
                    style={{
                        width: "100%",
                        height: "100px",
                        marginBottom: "10px",
                    }}
                />

                {keyboardLayout ? (
                    <Keyboard
                        keyboardRef={(r) => {
                            keyboardInstance.current = r;
                        }}
                        layoutName={shiftActive ? "shift" : "default"}
                        onChange={onKeyboardChange}
                        input={keyboardInput}
                        layout={keyboardLayout}
                        onKeyPress={onKeyPress}
                    />
                ) : (
                    <Spin />
                )}
            </Modal>
        </div>
    );
};

export default MultiOutputTranslation;
