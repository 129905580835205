import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import ButtonCard from "./ButtonCard";
import styles from "./ButtonContainer.module.css";
import CaseUpload from "../../../CaseUpload";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../../../RecoilState/userState";
import { flagState } from "../../../../RecoilState/flagState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { dashboardData } from "../../../../RecoilState/dashboardData";
import { companyDetailsState } from "../../../../RecoilState/CompanyDetails";
import { multipleFactoriesState } from "../../../../RecoilState/multipleFactoriesState";
import dayjs from "dayjs";
import UIDialog from "../UIDialog";
import ExportDataForm from "../ExportDataForm";

const buttonData = [
    {
        text: "Case Upload",
        color: "purple",
        icon: "/assets/images/ButtonLogos/icon/action/note_add_24px.svg",
        clicker: "openCaseUploadModal",
    },
];

export default function ButtonContainer({ tourRef, setTourData }) {
    const [user] = useRecoilState(userState);
    const [companyDetails] = useRecoilState(companyDetailsState);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [alldashboardData] = useRecoilState(dashboardData);
    const [flag, setFlagState] = useRecoilState(flagState);
    const [factories, setFactories] = useRecoilState(multipleFactoriesState);
    const [FactoryDataList] = useRecoilState(FactoryData);
    const [uploadModalIsOpen, setUploadIsOpen] = React.useState(false);
    const [factoryOptions, setFactoryOptions] = useState([]);
    let navigate = useNavigate();
    const location = useLocation();

    function openUploadModal() {
        setUploadIsOpen(true);
    }

    const handleChange = (value) => {
        const selectedValues = Array.isArray(value) ? value : [value];
        const factoriesSelected = FactoryDataList.filter((factory) =>
            selectedValues.includes(factory.id)
        );
        setFactories(factoriesSelected);
    };

    function closeModal() {
        setUploadIsOpen(false);
        setFlagState(!flag);
    }

    function handleButtonClick(clicked) {
        if (clicked === "openCaseUploadModal") {
            openUploadModal();
        } else {
            navigate(`/BroadcastMsg`);
        }
    }

    const QCbuttonData = [
        {
            text: "Intro",
            image: "/assets/images/QCReport/Play.svg",
            ref: tourRef.introRef,
        },
        {
            text: "Case Reviewed",
            value:
                alldashboardData.length !== 0
                    ? `${alldashboardData.reviewed}/${alldashboardData.total}`
                    : "N/A",
            ref: tourRef.casesRef,
        },
        {
            text: "Due Date",
            value:
                alldashboardData.length !== 0
                    ? dayjs(alldashboardData.dueDate).format("DD-MM-YYYY")
                    : "N/A",
            ref: tourRef.dateRef,
        },
    ];

    useEffect(() => {
        if (FactoryDataList) {
            setFactoryOptions(
                FactoryDataList.map((factory) => {
                    return {
                        label: `${factory.Code} ${factory.Location}`,
                        value: factory.id,
                    };
                })
            );
        }
    }, [FactoryDataList]);

    return (
        <div className={styles.ButtonContainer}>
            <Modal
                open={uploadModalIsOpen}
                onCancel={closeModal}
                closable={false}
                footer={null}
                width="80%"
                style={{
                    top: 120,
                    height: "63%",
                }}>
                <CaseUpload modalCloser={closeModal} />
            </Modal>
            {user.role === "QC" ? (
                <div className={styles.QCButtonContainer}>
                    {QCbuttonData.map((button, index) => (
                        <div
                            key={index}
                            className={styles.ButtonCard}
                            style={{ width: button.text === "Intro" && "auto" }}
                            onClick={() => {
                                button.text === "Intro" &&
                                    setTourData((tour) => ({
                                        ...tour,
                                        open: true,
                                    }));
                            }}
                            ref={button.ref}>
                            {button.image && (
                                <img
                                    src={button.image}
                                    alt="intro icon"
                                    style={{ marginRight: "-20px" }}
                                />
                            )}
                            <span className={styles.value}>{button.value}</span>
                            <span>{button.text}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    style={{
                        marginRight: "1em",
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                    }}>
                    {!companyDetails.online_case_upload &&
                    user.role !== "SUPER_ADMIN" &&
                    user.role !== "FACTORY_ADMIN" &&
                    user.role !== "REGIONAL_ADMIN" &&
                    user?.role !== "LEAD_SUPERVISOR"
                        ? buttonData.map((button) => (
                              <div
                                  key={button.text}
                                  onClick={() => {
                                      handleButtonClick(button.clicker);
                                  }}>
                                  <ButtonCard
                                      id={"CaseUploadButton"}
                                      text={button.text}
                                      colorLogo={button.color}
                                      icon={button.icon}
                                  />
                              </div>
                          ))
                        : (user.role === "SUPER_ADMIN" ||
                              user?.role === "LEAD_SUPERVISOR" ||
                              user.role === "REGIONAL_ADMIN") &&
                          location.search === "?critical=false" &&
                          FactorySelected && (
                              <Select
                                  mode="tags"
                                  data-testid="multi-factory-select"
                                  allowClear
                                  size="large"
                                  showSearch
                                  filterOption={(input, option) =>
                                      (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                  }
                                  tokenSeparators={[","]}
                                  onChange={handleChange}
                                  style={{ width: "350px" }}
                                  options={factoryOptions}
                                  placeholder={
                                      FactorySelected
                                          ? `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                          : FactoryDataList &&
                                            FactoryDataList.length > 0
                                          ? `${FactoryDataList[0]?.Code} - ${FactoryDataList[0]?.Location}`
                                          : "No Factory Data"
                                  }
                                  defaultValue={
                                      factories
                                          ? factories.map((item) => item.id)
                                          : FactorySelected.id
                                  }
                              />
                          )}
                    <UIDialog>
                        <ExportDataForm />
                    </UIDialog>
                </div>
            )}
        </div>
    );
}
