import React from "react";
import { Form, Input, Select, Row, Col, DatePicker, Alert } from "antd";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import UploadComponent from "../UploadComponent/UploadHeaderFile";
import AddEditDropdown from "./AddEditDropdown";
import dayjs from "dayjs";

const renderFormItem = (field, caseID, company, form) => {
    switch (field.type) {
        case "input":
            return <Input placeholder={field.placeholder || field.label} />;
        case "select":
            return (
                <Select placeholder={field.placeholder || field.label}>
                    {field.options.map((option, index) => (
                        <Select.Option key={index} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "textarea":
            return (
                <Input.TextArea
                    rows={4}
                    placeholder={field.placeholder || field.label}
                />
            );
        case "date":
            return (
                <DatePicker
                    disabledDate={
                        field.disableFutureDates
                            ? (current) =>
                                  current && current > dayjs().endOf("day")
                            : undefined
                    }
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                    placeholder={field.placeholder || field.label}
                />
            );
        case "upload":
            return (
                <UploadComponent
                    disableUpload={field.disableUpload}
                    externalFileList={field.fileList || []}
                    onExternalFileListChange={(newFileList) =>
                        field.setFileList(newFileList)
                    }
                    Files={[]}
                    maxFiles={field.maxFiles || "5"}
                    companyFk={company}
                    model={field.model || "default_model"}
                    allowedFileTypes={
                        field.allowedFileTypes || ["jpeg", "jpg", "png"]
                    }
                    modelMethod="new"
                    onModelCreated={field.handleUpload}
                    setOnModalCreated={field.setHandleUpload}
                    id={caseID}
                    onUploadComplete={field.onUploadComplete}
                    showAsANamedHyperLink={false}
                    onFileListChange={(newFileList) => {
                        form.setFieldsValue({ [field.name]: newFileList });
                    }} // Update form state
                />
            );
        case "addEditDropdown":
            return (
                <AddEditDropdown
                    includesPermission={true}
                    fieldValue={field.fieldValue}
                    setFieldValue={field.setFieldValue}
                    handleDropdown={field.handleDropdown}
                    setHandleDropdown={field.setHandleDropdown}
                    fields={field.fields}
                    RenameField={field.RenameField}
                    allowSpaces={field.allowSpaces}
                />
            );
        default:
            return <Input placeholder={field.placeholder || field.label} />;
    }
};

const CaseUploadForm = ({
    form,
    fieldsData,
    handleSubmit,
    handleValuesChange,
    warningMessage,
    caseID,
}) => {
    const [user] = useRecoilState(userState);
    const company = user.company_fk;
    return (
        <div>
            <Form
                form={form}
                layout="horizontal"
                onFinish={handleSubmit}
                onValuesChange={handleValuesChange}>
                <Row gutter={16}>
                    {fieldsData.map((field, index) => (
                        <Col key={index} span={field.colSpan || 24}>
                            <Form.Item
                                label={field.label}
                                name={field.name}
                                initialValue={field.defaultValue}
                                rules={field.rules || []}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}>
                                {renderFormItem(field, caseID, company, form)}
                            </Form.Item>
                        </Col>
                    ))}
                </Row>

                {warningMessage && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <Alert
                            message="Warning"
                            description={warningMessage}
                            type="warning"
                            showIcon
                            style={{
                                marginBottom: 16,
                                backgroundColor: "#FFF7E6",
                                borderColor: "#FFD591",
                                color: "#AD8B00",
                                width: "50%",
                            }}
                        />
                    </div>
                )}

                <Form.Item wrapperCol={{ span: 24 }}>
                    <Row justify="end">
                        <Col>
                            <button
                                type="default"
                                className="secondaryButton"
                                htmlType="reset">
                                Clear All
                            </button>
                        </Col>
                        <Col>
                            <button
                                type="primary"
                                htmlType="submit"
                                className="primaryButton"
                                style={{ marginLeft: "8px" }}>
                                Submit
                            </button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CaseUploadForm;
