import React, { useState, useEffect } from "react";
import { message, Tabs } from "antd";
import BasicSendMessage from "./BasicSendMessage";
import PreviewSendMessage from "./PreviewSendMessage";
import VariableSendMessage from "./VariableSendMessage";
import {
    getSentMessagePreviewData,
    getTemplateData,
    postTemplateData,
    sendMessage,
} from "../../Adapters/SendMessage";
import { userState } from "../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { SendMessageFlag } from "../../RecoilState/SendMessageFlag";
import useEventMetricsUpdater from "../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";

const SendMessageMain = ({ allTemplates, caseData, hideModal }) => {
    const [user] = useRecoilState(userState);
    const [sendMessageFlag, setSendMessageFlag] =
        useRecoilState(SendMessageFlag);
    const [resmessage, setresmessage] = React.useState("");
    const [templateID, setTemplateID] = React.useState("");
    const [activeTab, setActiveTab] = useState("1");
    const [language, setLanguage] = useState("");
    const [template, settemplate] = useState("");
    const [variableData, setVariableData] = useState("");
    const [previewScreenData, setPreviewScreenData] = useState();
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [tabState, setTabState] = useState({
        basic: {
            disabled: "true",
        },
        variable: {
            disabled: "false",
        },
        preview: {
            disabled: "false",
        },
    });

    const getVariableData = async () => {
        const id = template && language && allTemplates[template][language];
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template/`
        );
        setTemplateID(id);
        queryUrl.searchParams.append("templateID", id);
        queryUrl.searchParams.append("caseID", caseData?.id);
        queryUrl.searchParams.append("language", language);

        try {
            const res = await postTemplateData(queryUrl);
            setVariableData(res?.data?.message_body);
            setTabState((prevState) => ({
                ...prevState,
                variable: {
                    ...prevState.variable,
                    disabled: "true",
                },
            }));
            setActiveTab("2");
        } catch (error) {
            message.error(
                error.response.data.errorMessage
                    ? error.response.data.errorMessage
                    : "Error fetching data"
            );
        }
    };

    const getPreviewScreensDataHandler = async (translatedDataObj) => {
        try {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/varmap/`
            );
            let objectString = JSON.stringify(translatedDataObj);
            queryUrl.searchParams.append("inputs", objectString);

            const res = await getSentMessagePreviewData(queryUrl);

            setPreviewScreenData(res?.data.message_body[0]);
            setresmessage(res?.data.message_body[0].body);
            setActiveTab("3");
            setTabState((prevState) => ({
                ...prevState,
                preview: {
                    ...prevState.preview,
                    disabled: "true",
                },
            }));
        } catch (error) {
            message.error(
                error.response.data.errorMessage
                    ? error.response.data.errorMessage
                    : "Error fetching preview screen data"
            );
        }
    };
    const sendMessageCall = (eventName, actionType) => {
        sendMessage(
            templateID,
            caseData.id,
            resmessage,
            user.role,
            caseData.Company
        )
            .then(() => {
                message.success("Message sent successfully");
                setSendMessageFlag(!sendMessageFlag);
                hideModal();
            })
            .catch((err) =>
                message.error("Unexpected error occurred. Please try again!")
            );
        hideModal();
        updateEventMetrics(eventName, actionType);
    };
    const debouncedSendMessageCall = debounce(sendMessageCall, 1000);
    const items = [
        {
            key: "1",
            label: `Basic`,

            children: (
                <BasicSendMessage
                    template={template}
                    language={language}
                    allTemplates={allTemplates}
                    settemplate={settemplate}
                    setLanguage={setLanguage}
                    setVariableData={setVariableData}
                    setTabState={setTabState}
                    tabState={tabState}
                    setresmessage={setresmessage}
                    getVariableData={getVariableData}
                    setActiveTab={setActiveTab}
                />
            ),
        },
        {
            key: "2",
            label: `Variable`,
            children: (
                <VariableSendMessage
                    variableData={variableData}
                    setTabState={setTabState}
                    setActiveTab={setActiveTab}
                    getPreviewScreensDataHandler={getPreviewScreensDataHandler}
                    templateID={templateID}
                    language={language}
                />
            ),
            disabled: tabState.variable.disabled !== "true",
        },
        {
            key: "3",
            label: `Message`,
            children: (
                <PreviewSendMessage
                    sendMessageCall={debouncedSendMessageCall}
                    previewScreenData={previewScreenData}
                />
            ),
            disabled: tabState.preview.disabled !== "true",
        },
    ];

    return (
        <div>
            <div>
                <Tabs
                    defaultActiveKey="1"
                    activeKey={activeTab}
                    items={items}
                    onChange={(key) => {
                        setActiveTab(key);
                    }}
                    onTabClick={(key) => {
                        setActiveTab(key);
                    }}
                />
            </div>
        </div>
    );
};

export default SendMessageMain;
