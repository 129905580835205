import React from "react";
import styles from "../../CaseReport.module.css";
import { Select, Popover, Form, Input, Radio, Row, Col, Divider } from "antd";
import UserDisplayComp from "../../../../containers/UserDisplayComp";
import { Upload, message } from "antd";
import { fileUrlState } from "../../../../RecoilState/fileUrlState";
import { useRecoilState } from "recoil";
import { getCaseDetailsByID } from "../../../../Adapters/CaseCRUDCalls";
import { useState, useEffect } from "react";
import CaseReportBlock from "../../../../components/Utils/CaseReportBlock";
import { valueUpdateFlag } from "../../../../RecoilState/valueUpdateFlag";
import { getCounterFromCaseStatus } from "../../../../hooks/getCounterFromCaseStatus";
import UploadComponent from "../../../../components/UploadComponent/UploadHeaderFile";

import {
    fetchManagers,
    fetchRA,
    fetchTRB,
    getCategories,
    getCheckForAutomationValue,
    getLanguageList,
    makeCaseUnresponsiveCall,
    ResolveCaseByCT,
} from "../../../../Adapters/CaseReportCalls";
import { useNavigate } from "react-router";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";
import { userState } from "../../../../RecoilState/userState";
import { factoryState } from "../../../../RecoilState/FactoryState";
import ActionModal from "../../../../components/Utils/ActionModal";
import ConfirmationModal from "../../../../components/Utils/ConfirmationModal";
import { LoadingOverlay } from "../../../../components/Utils/LoadingOverlay";
const { Option } = Select;
const { Dragger } = Upload;

export default function CaseReportDetails({
    highlightedFields,
    saveDetailsandComments,
    saveDraftDetailsandComments,
    caseDatastate,
    setcaseDatastate,
    showWorkerLanguage,
}) {
    const [TRBData, setTRBData] = useState();
    const [ManagerData, setManagerData] = useState();
    const [RAData, setRAData] = useState();
    const [previousRoute] = useRecoilState(previousRouteData);
    const [user, setUser] = useRecoilState(userState);
    const [caseDetailsForm] = Form.useForm();
    const [caseValueUpdateFlag, setCaseValueUpdateFlag] =
        useRecoilState(valueUpdateFlag);
    const [uploadedFiles, setUploadedFiles] = useState();
    const refreshFile = () => {
        getCaseDetailsByID(caseDatastate.id).then((res) => {
            setcaseDatastate((prevState) => ({
                ...prevState, // Keep all existing form data and state
                File: res.data.File, // Only update the File data from the response
            }));
        });
    };
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [CaseValidation, setCaseValidation] = useState(true);
    const { updateEventMetrics } = useEventMetricsUpdater();
    let navigate = useNavigate();
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [grievanceOptions, setGrievanceOptions] = useState([]);
    const [workflowData, setWorkflowData] = useState("");
    const [is_other_Field_active, set_is_other_Field_active] = useState(false);
    const [modalStates, setModalStates] = useState({
        showAutomationModal: false,
        showConfirmationModal: false,
    });
    const [handleUpload, setHandleUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [currentAction, setCurrentAction] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const allowedFileTypes = [
        "jpeg",
        "jpg",
        "png",
        "svg",
        "pdf",
        "csv",
        "pps",
        "ppt",
        "pptx",
        "txt",
        "xls",
        "xlsx",
        "xlsm",
        "gif",
        "doc",
        "docx",
        "tex",
        "rtf",
        "mp4",
        "mpg",
        "mpeg",
        "mov",
        "avi",
        "log",
        "aif",
        "mp3",
        "mpa",
        "wav",
        "wma",
    ];
    const shouldDeleteDocument = () => {
        const caseStatusCounter = getCounterFromCaseStatus(
            caseDatastate.CaseStatus
        );
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseDatastate.CaseCategory != "POSH" &&
                caseDatastate.CaseCategory != "Special Cases") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };

    const shouldDownloadDocument = () => {
        const caseStatusCounter = getCounterFromCaseStatus(
            caseDatastate.CaseStatus
        );
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseDatastate?.Category?.workflow !==
                    "Poshratroubleshootflow" &&
                caseDatastate?.Category?.workflow !== "Ratroubleshootflow") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };

    const shouldDisableUpload = () => {
        const caseStatusCounter = getCounterFromCaseStatus(
            caseDatastate.CaseStatus
        );
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseDatastate?.Category?.workflow !==
                    "Poshratroubleshootflow" &&
                caseDatastate?.Category?.workflow !== "Ratroubleshootflow") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };
    const [action, setAction] = useState(null); // State to track the action
    useEffect(() => {
        const fetchCategories = async () => {
            if (user.company_fk) {
                const res = await getCategories(user.company_fk);
                const categories = res.data;
                setCategoriesData(categories);
                const uniqueCategories = Array.from(
                    new Set(categories.map((item) => item.category))
                );
                const categoryOptions = uniqueCategories.map((category) => ({
                    label: category,
                    value: category,
                }));
                setCategoryOptions(categoryOptions);
            }
        };

        fetchCategories();
    }, [user.company_fk, caseDatastate]);

    const handleCategoryChange = (selectedCategory) => {
        // Filter the data based on the selected category
        const filteredData = categoriesData.filter(
            (item) => item.category === selectedCategory
        );

        // Extract unique subcategories with their IDs
        const uniqueSubcategories = [
            ...new Map(
                filteredData.map((item) => [item.sub_category, item.id])
            ).entries(),
        ];

        // Map to options for Select component
        const subCategoryOptions = uniqueSubcategories.map(
            ([subCategory, id]) => ({
                label: subCategory,
                value: id,
            })
        );

        setWorkflowData("");
        set_is_other_Field_active("");
        setSubCategoryOptions(subCategoryOptions);
        setGrievanceOptions([]);
        caseDetailsForm.setFieldsValue({
            priority: null,
        });
    };

    const handleSubCategoryChange = (selectedSubCategory) => {
        const subcategoryData = categoriesData.find(
            (item) => item.id === selectedSubCategory
        );

        // If subcategoryData is found, map its event_datapoint_type_fk to options
        if (subcategoryData) {
            const grievanceOptions =
                subcategoryData.event_datapoint_type_fk.map((grievance) => ({
                    label: grievance.name,
                    value: grievance.id,
                }));

            setWorkflowData(subcategoryData.workflow);
            set_is_other_Field_active(
                subcategoryData.is_subcategory_details_enabled
            );
            // Set the grievance options ]
            setGrievanceOptions(grievanceOptions);
            caseDetailsForm.setFieldsValue({
                priority: null,
            });
        } else {
            // If no subcategoryData is found, clear the grievance options
            setGrievanceOptions([]);
            caseDetailsForm.setFieldsValue({
                priority: null,
            });
        }
    };

    // Function to check if a field should be highlighted
    const isFieldHighlighted = (fieldName) => {
        // Adjust the logic based on how you want to highlight (Exception, Prefilled, etc.)
        return (
            highlightedFields?.Exception?.includes(fieldName) ||
            highlightedFields?.Prefilled?.includes(fieldName)
        );
    };

    useEffect(() => {
        const files = caseDatastate?.File
            ? Object.keys(caseDatastate?.File)
            : [];
        if (files.length > 0) {
            let value = files[files.length - 1];
            setUploadedFiles(caseDatastate?.File[value]);
        }
    }, [caseDatastate]);
    useEffect(() => {
        if (
            !TRBData && user.company_fk != undefined && user.factory_fk
                ? user.factory_fk
                : FactorySelected?.id
        ) {
            fetchTRB(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setTRBData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
        setTRBData,
    ]);
    useEffect(() => {
        if (
            !RAData && user.company_fk != undefined && user.factory_fk
                ? user.factory_fk
                : FactorySelected?.id
        ) {
            fetchRA(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setRAData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
    ]);
    useEffect(() => {
        if (
            !ManagerData &&
            user.company_fk != undefined &&
            (user.factory_fk ? user.factory_fk : FactorySelected?.id)
        ) {
            fetchManagers(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setManagerData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
        setManagerData,
    ]);

    const onUploadComplete = () => {
        setFileUploaded(true);
    };

    const saveAndUpdate = (type, eventName, actionType) => {
        if (type === "submission") {
            saveDetailsandComments(caseDetailsForm.getFieldsValue(true));
        } else if (type === "draft") {
            saveDraftDetailsandComments(caseDetailsForm.getFieldsValue(true));
        }
        setCaseValueUpdateFlag((prevFlag) => !prevFlag);
        updateEventMetrics(eventName, actionType);
    };

    const handleAction = (eventName, actionType, type) => {
        setHandleUpload(true);
        setCurrentAction({ eventName, actionType, type });
        if (fileList.length > 0) {
            setFileLoading(true);
            handleCancel("showAutomationModal");
            handleCancel("showConfirmationModal");

            // If there are files to upload, wait for the upload to complete
            if (fileUploaded) {
                saveAndUpdate(type, eventName, actionType);
            }
        } else {
            // If there are no files to upload, proceed with the normal flow
            saveAndUpdate(type, eventName, actionType);
        }
    };

    const handleSubmission = (event, eventName, actionType) => {
        if (!(event.CaseValidation === false && user.role === "CR")) {
            handleAction(eventName, actionType, "submission");
        }
    };

    const handleDraft = (eventName, actionType) => {
        handleAction(eventName, actionType, "draft");
    };

    useEffect(() => {
        if (fileUploaded && currentAction) {
            saveAndUpdate(
                currentAction.type,
                currentAction.eventName,
                currentAction.actionType
            );
            setCurrentAction(null); // Reset the current action
            setFileLoading(false);
        }
    }, [fileUploaded, currentAction]);
    //setting all the initial values of form

    useEffect(() => {
        // Capture the current form values
        const currentFormValues = caseDetailsForm.getFieldsValue();

        // Prepare to update only fields that have not been modified
        const updatedValues = {};
        Object.keys(currentFormValues).forEach((key) => {
            if (
                currentFormValues[key] === undefined ||
                currentFormValues[key] === caseDatastate[key]
            ) {
                // Use the value from caseDatastate if the form value is undefined or matches the caseDatastate value
                updatedValues[key] = caseDatastate[key];
            } else {
                // Otherwise, retain the current form value
                updatedValues[key] = currentFormValues[key];
            }
        });

        if (
            currentFormValues["priority"] !== (undefined || null) ||
            caseDatastate?.priority?.id !== undefined
        ) {
            updatedValues["priority"] = currentFormValues["priority"]
                ? currentFormValues["priority"]?.id
                    ? currentFormValues["priority"]?.id
                    : currentFormValues["priority"]
                : caseDatastate?.priority?.id;
        }
        if (
            currentFormValues["category"] !== undefined ||
            caseDatastate?.Category?.category !== undefined
        ) {
            handleCategoryChange(
                currentFormValues["category"]
                    ? currentFormValues["category"]
                    : caseDatastate?.Category?.category
            );
            updatedValues["category"] = currentFormValues["category"]
                ? currentFormValues["category"]
                : caseDatastate?.Category?.category;
        }

        if (
            currentFormValues["subCategory"] !== undefined ||
            caseDatastate?.Category?.id !== undefined
        ) {
            handleSubCategoryChange(
                currentFormValues["subCategory"]
                    ? currentFormValues["subCategory"]
                    : caseDatastate?.Category?.id
            );
            updatedValues["subCategory"] = currentFormValues["subCategory"]
                ? currentFormValues["subCategory"]
                : caseDatastate?.Category?.id;
        }
        updatedValues["Document"] = caseDatastate.File;
        caseDetailsForm.setFieldsValue(updatedValues);
    }, [caseDatastate, RAData, categoriesData]);

    const [languageList, setLanguageList] = useState("");

    useEffect(() => {
        if (caseDatastate?.ReportingMedium === "Call") {
            const fetchData = async () => {
                try {
                    const res = await getLanguageList();

                    setLanguageList(res.data);
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            };

            fetchData();
        }
    }, [caseDatastate?.ReportingMedium]);
    const onValuesChangeHandler = () => {
        const selectedCategory = caseDetailsForm.getFieldValue("category");
    };
    function checkPermissionForInputFields(role) {
        const counter = getCounterFromCaseStatus(caseDatastate.CaseStatus);

        switch (role) {
            case "CR":
                return counter <= 1;

            case "CM":
                return counter <= 2;

            default:
                return false;
        }
    }
    function checkPermissionForHideFields(role) {
        const counter = getCounterFromCaseStatus(caseDatastate.CaseStatus);
        switch (role) {
            case "CR":
                return workflowData === "Cttroubleshootflow" && counter !== 1;

            // case "CM":
            //     return workflowData === "Cttroubleshootflow" && counter !== 1;

            case "REGIONAL_ADMIN":
                if (caseDatastate?.CaseManager === null) {
                    return false;
                }
                return counter > 2;

            case "SUPER_ADMIN":
                return workflowData === "Cttroubleshootflow" && counter !== 1;

            case "LEAD_SUPERVISOR":
                return workflowData === "Cttroubleshootflow" && counter !== 1;

            default:
                return true;
        }
    }

    const handleCancel = (modalName) => {
        setModalStates((prevState) => ({
            ...prevState,
            [modalName]: false,
        }));
    };
    const submitHandler = async () => {
        try {
            const res = await getCheckForAutomationValue(caseDatastate?.id);

            if (res.data.showConfirmation === true) {
                setModalStates((prevState) => ({
                    ...prevState,
                    showAutomationModal: true,
                }));
            } else if (res.data.showConfirmation === false) {
                setModalStates((prevState) => ({
                    ...prevState,
                    showConfirmationModal: true,
                }));
            }
        } catch (error) {
            if (error.response.status === 400) {
                message.error(error.response.data.errorMessage);
            } else {
                message.error(
                    error.response && error.response.data.errorMessage
                        ? error.response.data.errorMessage
                        : "An error occurred while checking for automation."
                );
            }
        }
    };
    const markCaseUnresponsive = () => {
        const res = makeCaseUnresponsiveCall(caseDatastate?.id);
        console.log("res", res);
        setModalStates((prevState) => ({
            ...prevState,
            showAutomationModal: false,
        }));
        navigate(previousRoute.defaultRoute);
    };

    const actions = [
        {
            text: "Case will be moved to archive, if you haven’t received any reply within the timeline.",
            buttonText: "Mark Unresponsive",
            handler: markCaseUnresponsive,
        },
        {
            text: "Move to next if issue requires further attention.",
            buttonText: "Submit case to next user",
            handler: handleSubmission,
        },
    ];

    const debouncedHandleDraft = debounce(handleDraft, 500);
    const debounceHandleSubmission = debounce(submitHandler, 500);

    const handleFinish = (e) => {
        if (action === "submit") {
            debounceHandleSubmission(
                e,
                "caseDashboardSubmit",
                "Case Dashboard- Submit"
            );
        }
    };
    // The function to close an invalid case
    const handleCloseInvalidCase = async () => {
        setAction("close");
        try {
            const res = await ResolveCaseByCT(caseDatastate.id);
            if (res) {
                message.success("Hey CR! The case is closed now!");
                navigate(previousRoute.defaultRoute);
            } else {
                message.error("Failed to close the case. Please try again.");
            }
        } catch (error) {
            if (
                error?.response?.data?.errorMessage ===
                "Can't Close the Case, awaiting for worker's response."
            ) {
                message.error(
                    "Please be patient while awaiting the worker's response to your requirement message.",
                    6
                );
            } else {
                message.error("An error occurred while closing the case.");
            }
        }
    };
    return (
        <>
            {fileLoading && (
                <LoadingOverlay text={"Uploading document, please wait..."} />
            )}

            <div style={{ position: "relative", marginTop: "3rem" }}>
                <Form
                    id="caseReportDetailsForm"
                    name="Case Details Form"
                    form={caseDetailsForm}
                    initialValues={caseDatastate}
                    onValuesChange={onValuesChangeHandler}
                    onFinish={handleFinish}>
                    <Row gutter={[16, 48]}>
                        {showWorkerLanguage &&
                            (caseDatastate?.ReportingMedium === "Call" &&
                            getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                                2 &&
                            user.role === "CR" ? (
                                <Col span={12}>
                                    <Form.Item
                                        initialValue={
                                            caseDatastate.workerLanguage
                                        }
                                        name="workersLanguage"
                                        label="Worker's Language"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select Language",
                                            },
                                        ]}>
                                        <Select id="workersLanguageSelect">
                                            {/* <Option>abc</Option> */}
                                            {languageList?.languages?.map(
                                                (lang, index) => (
                                                    <>
                                                        <Option
                                                            key={index}
                                                            value={lang}>
                                                            {lang}
                                                        </Option>
                                                    </>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={12}>
                                    <CaseReportBlock
                                        label="Worker's Language"
                                        displayMessage={
                                            caseDatastate.workerLanguage
                                        }
                                    />
                                </Col>
                            ))}
                        <Col span={12}>
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="CaseReporter"
                                    label="Reporter"
                                    className={styles.label}>
                                    <Select
                                        id="caseReporterSelect"
                                        placeholder="Select Reporter"
                                        disabled={true}
                                        options={[
                                            {
                                                label: (
                                                    <UserDisplayComp
                                                        userid={
                                                            caseDatastate[
                                                                "CaseReporter"
                                                            ]
                                                        }
                                                    />
                                                ),
                                                value: caseDatastate[
                                                    "CaseReporter"
                                                ],
                                            },
                                        ]}></Select>
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Reporter"
                                            displayMessage={
                                                <UserDisplayComp
                                                    userid={
                                                        caseDatastate[
                                                            "CaseReporter"
                                                        ]
                                                    }
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={[16, 48]} className={styles.topPadding}>
                        <Col span={12}>
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="caseDetails"
                                    label="Case Details"
                                    className={styles.label}
                                    initialValue={caseDatastate.CaseDetails}
                                    rules={[
                                        {
                                            required:
                                                CaseValidation &&
                                                user.role === "CR",
                                            message:
                                                "Please enter the Case Details",
                                        },
                                    ]}
                                    validateStatus={
                                        isFieldHighlighted("CaseDetails")
                                            ? "error"
                                            : ""
                                    }>
                                    <Input.TextArea
                                        id="caseDetailsTextArea"
                                        disabled={!CaseValidation}
                                        readOnly={
                                            user.role === "CR" ? false : true
                                        }
                                        rows={4}
                                        cols={50}
                                    />
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Details"
                                            displayMessage={
                                                caseDatastate.CaseDetails
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                            {caseDatastate?.reopened === false &&
                                (checkPermissionForInputFields(user.role) ? (
                                    <Form.Item
                                        name="CaseValidation"
                                        label="Case Valid"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    CaseValidation &&
                                                    user.role === "CR",
                                                message:
                                                    "Please enter the Case Validation",
                                            },
                                        ]}>
                                        <Radio.Group
                                            id="caseValidationRadioGroup"
                                            onChange={(e) => {
                                                setCaseValidation(
                                                    e.target.value
                                                );
                                            }}>
                                            <Radio
                                                value={true}
                                                disabled={
                                                    user.role !== "CR"
                                                        ? true
                                                        : false
                                                }>
                                                Yes
                                            </Radio>
                                            <Radio
                                                value={false}
                                                disabled={
                                                    user.role !== "CR"
                                                        ? true
                                                        : false
                                                }>
                                                No
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                ) : (
                                    <Row gutter={[16, 48]}>
                                        <Col span={12}>
                                            <CaseReportBlock
                                                label="Case Valid"
                                                displayMessage={caseDatastate?.CaseValidation?.toString()}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="Document"
                                label="Document"
                                className={styles.label}>
                                <UploadComponent
                                    document_delete_on={!shouldDeleteDocument()}
                                    document_download_on={
                                        !shouldDownloadDocument()
                                    }
                                    disableUpload={shouldDisableUpload()}
                                    externalFileList={fileList}
                                    onExternalFileListChange={(newFileList) =>
                                        setFileList(newFileList)
                                    }
                                    Files={uploadedFiles}
                                    maxFiles="5"
                                    companyFk={user.company_fk}
                                    model="case"
                                    allowedFileTypes={allowedFileTypes}
                                    modelMethod="new"
                                    scrollable={true}
                                    onModelCreated={handleUpload}
                                    setOnModalCreated={setHandleUpload}
                                    id={caseDatastate.id}
                                    onUploadComplete={onUploadComplete}
                                    refresh={refreshFile}
                                    showAsANamedHyperLink={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12} id="caseNatureSelect">
                            {checkPermissionForInputFields(user.role) ? (
                                <>
                                    <Form.Item
                                        name="CaseNature"
                                        label="Case Nature"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    CaseValidation &&
                                                    user.role === "CR",
                                                message:
                                                    "Please enter the Case Nature",
                                            },
                                        ]}>
                                        <Select
                                            disabled={
                                                user.role === "CT" ||
                                                !CaseValidation
                                            }>
                                            <Option value="Complaint">
                                                Complaint
                                            </Option>
                                            <Option value="Query">Query</Option>
                                            <Option value="Suggestion">
                                                Suggestion
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Nature"
                                            displayMessage={
                                                caseDatastate.CaseNature
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12} id="categorySelect">
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="category"
                                    label="Category"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                CaseValidation &&
                                                (user.role === "CR" ||
                                                    user.role === "CM"),
                                            message:
                                                "Please enter the Category",
                                        },
                                    ]}>
                                    <Select
                                        disabled={
                                            user.role === "CT" ||
                                            !CaseValidation
                                        }
                                        defaultValue={
                                            caseDatastate?.Category?.id
                                        }
                                        onChange={(value) => {
                                            handleCategoryChange(value);
                                            caseDetailsForm.setFieldsValue({
                                                subCategory: null,
                                            });
                                        }}
                                        options={categoryOptions}
                                    />
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Category"
                                            displayMessage={
                                                caseDatastate?.Category
                                                    ?.category
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>

                        {user.role === "CR" &&
                        (workflowData === "Ratroubleshootflow" ||
                            workflowData === "Poshratroubleshootflow") ? (
                            <Col span={12} id="regionalAdminCRPosh">
                                {getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 3 ||
                                (getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 2 &&
                                    user.role === "CR") ||
                                user.role === "SUPER_ADMIN" ||
                                user.role == "FACTORY_ADMIN" ||
                                user.role === "LEAD_SUPERVISOR" ||
                                user.role === "REGIONAL_ADMIN" ? (
                                    <Row gutter={[16, 48]}>
                                        <Col span={22}>
                                            <CaseReportBlock
                                                label="Regional Admin"
                                                displayMessage={
                                                    caseDatastate.RegionalAdmin && (
                                                        <UserDisplayComp
                                                            userid={
                                                                caseDatastate[
                                                                    "RegionalAdmin"
                                                                ]
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Form.Item
                                        name="regionalAdmin"
                                        label="Regional Admin"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    CaseValidation &&
                                                    user.role === "CR" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "POSH" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "Special Cases",
                                                message:
                                                    "Please enter the Regional Admin",
                                            },
                                        ]}>
                                        {RAData?.length > 0 ? (
                                            <div>
                                                {caseDetailsForm.setFieldsValue(
                                                    {
                                                        regionalAdmin:
                                                            RAData[0].role_id,
                                                    }
                                                )}
                                                <Input
                                                    value={RAData[0].user.email}
                                                    placeholder="Regional Admin"
                                                    disabled={
                                                        !CaseValidation ||
                                                        user.role === "CT" ||
                                                        user.role === "CM" ||
                                                        caseDetailsForm.getFieldValue(
                                                            "category"
                                                        ) === "POSH" ||
                                                        caseDetailsForm.getFieldValue(
                                                            "category"
                                                        ) === "Special Cases"
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <p>
                                                No Regional Admin data available
                                            </p>
                                        )}
                                    </Form.Item>
                                )}
                            </Col>
                        ) : (
                            <Col span={12} id="caseManagerSelect">
                                {getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 3 ||
                                (getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 2 &&
                                    user.role === "CR") ||
                                user.role === "SUPER_ADMIN" ||
                                user.role == "FACTORY_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ||
                                user.role === "LEAD_SUPERVISOR" ? (
                                    <Row gutter={[16, 48]}>
                                        <Col span={22}>
                                            <CaseReportBlock
                                                label="Case Manager"
                                                displayMessage={
                                                    <UserDisplayComp
                                                        userid={
                                                            caseDatastate[
                                                                "CaseManager"
                                                            ]
                                                        }
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Form.Item
                                        name="CaseManager"
                                        label="Manager"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    CaseValidation &&
                                                    (user.role === "CR" ||
                                                        user.role === "CM"),
                                                message:
                                                    "Please enter the Manager",
                                            },
                                        ]}>
                                        <Select
                                            placeholder="Select Manager"
                                            disabled={
                                                !CaseValidation ||
                                                user.role == "CT" ||
                                                user.role == "CM"
                                            }>
                                            {ManagerData?.length > 0 &&
                                                ManagerData.map(
                                                    (item, index) => (
                                                        <Option
                                                            key={index}
                                                            value={
                                                                item.role_id
                                                            }>
                                                            {item.user.email}
                                                        </Option>
                                                    )
                                                )}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Col>
                        )}
                    </Row>

                    <Row gutter={[16, 48]}>
                        <Col span={12} id="subCategorySelect">
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="subCategory"
                                    label="Sub-Category"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                CaseValidation &&
                                                (user.role === "CR" ||
                                                    user.role === "CM"),
                                            message:
                                                "Please enter the Sub-Category",
                                        },
                                    ]}>
                                    <Select
                                        disabled={
                                            user.role === "CT" ||
                                            !CaseValidation
                                        }
                                        onChange={handleSubCategoryChange}
                                        options={subCategoryOptions}
                                    />
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Sub-Category"
                                            displayMessage={
                                                caseDatastate?.Category
                                                    ?.sub_category
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col span={12}>
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="CommentsByRep"
                                    label="Comments By Reporter"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                CaseValidation &&
                                                user.role === "CR",
                                            message: "Please enter the Comment",
                                        },
                                    ]}>
                                    <Input.TextArea
                                        id="commentsByRep"
                                        disabled={!CaseValidation}
                                        readOnly={
                                            user.role === "CR" ? false : true
                                        }
                                        rows={4}
                                        cols={50}
                                        maxLength={300}
                                        showCount
                                    />
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Comments By Reporter"
                                            displayMessage={
                                                caseDatastate.CommentsByRep
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12}>
                            {is_other_Field_active === true &&
                                (checkPermissionForInputFields(user.role) ? (
                                    <Form.Item
                                        name="Others_Category"
                                        label="Others"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    CaseValidation &&
                                                    user.role === "CR",
                                                message:
                                                    "Please enter the Others Input",
                                            },
                                        ]}>
                                        <Input.TextArea
                                            id="OthersCategoryFieldArea"
                                            readOnly={user.role === "CT"}
                                            rows={1}
                                            cols={50}
                                            maxLength={100}
                                        />
                                    </Form.Item>
                                ) : (
                                    <Row gutter={[16, 48]}>
                                        <Col span={22}>
                                            <CaseReportBlock
                                                label="Others"
                                                displayMessage={
                                                    caseDatastate?.Others_Category
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ))}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12} id="priorityGrievanceType">
                            {checkPermissionForInputFields(user.role) ? (
                                <Form.Item
                                    name="priority"
                                    label="Grievance Type"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                CaseValidation &&
                                                (user.role === "CR" ||
                                                    user.role === "CM"),

                                            message:
                                                "Please enter the Grievance Type",
                                        },
                                    ]}>
                                    <Select
                                        disabled={!CaseValidation}
                                        options={grievanceOptions}
                                        key={grievanceOptions.value}
                                        value={grievanceOptions.value}
                                    />
                                </Form.Item>
                            ) : (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Grievance Type"
                                            displayMessage={
                                                caseDatastate?.priority?.name
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Divider className={styles.caseRepDivider} />

                    {caseDatastate.CaseValidation === true &&
                        checkPermissionForHideFields(user.role) && (
                            <>
                                <Row
                                    gutter={[16, 48]}
                                    className={styles.topPadding}>
                                    <Col span={12}>
                                        {checkPermissionForInputFields(
                                            user.role
                                        ) ? (
                                            <Form.Item
                                                name="CommentsByMan"
                                                label="Comments By Manager"
                                                className={styles.label}
                                                rules={[
                                                    {
                                                        required:
                                                            CaseValidation,
                                                        message:
                                                            "Please enter the Comments",
                                                    },
                                                ]}>
                                                <Input.TextArea
                                                    id="commentsByManTextArea"
                                                    readOnly={
                                                        user.role === "CR" ||
                                                        user.role === "CT"
                                                            ? true
                                                            : false
                                                    }
                                                    rows={4}
                                                    cols={50}
                                                    maxLength={300}
                                                    showCount
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Row gutter={[16, 48]}>
                                                <Col span={22}>
                                                    <CaseReportBlock
                                                        label="Comments By Manager"
                                                        displayMessage={
                                                            caseDatastate.CommentsByMan
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                    {user.role === "CM" &&
                                    (workflowData === "Ratroubleshootflow" ||
                                        workflowData ===
                                            "Poshratroubleshootflow") ? (
                                        <Col span={12}>
                                            {getCounterFromCaseStatus(
                                                caseDatastate.CaseStatus
                                            ) >= 3 ||
                                            (getCounterFromCaseStatus(
                                                caseDatastate.CaseStatus
                                            ) >= 2 &&
                                                user.role === "CR") ||
                                            user.role === "SUPER_ADMIN" ||
                                            user.role == "FACTORY_ADMIN" ||
                                            user.role === "LEAD_SUPERVISOR" ||
                                            user.role === "REGIONAL_ADMIN" ? (
                                                <Row gutter={[16, 48]}>
                                                    <Col span={22}>
                                                        <CaseReportBlock
                                                            label="Regional Admin"
                                                            displayMessage={
                                                                caseDatastate.RegionalAdmin && (
                                                                    <UserDisplayComp
                                                                        userid={
                                                                            caseDatastate[
                                                                                "RegionalAdmin"
                                                                            ]
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Form.Item
                                                    name="regionalAdmin"
                                                    label="Regional Admin"
                                                    className={styles.label}
                                                    rules={[
                                                        {
                                                            required:
                                                                CaseValidation &&
                                                                user.role ===
                                                                    "CR" &&
                                                                caseDetailsForm.getFieldValue(
                                                                    "category"
                                                                ) !== "POSH" &&
                                                                caseDetailsForm.getFieldValue(
                                                                    "category"
                                                                ) !==
                                                                    "Special Cases",
                                                            message:
                                                                "Please enter the Regional Admin",
                                                        },
                                                    ]}>
                                                    {RAData?.length > 0 ? (
                                                        <div>
                                                            {caseDetailsForm.setFieldsValue(
                                                                {
                                                                    regionalAdmin:
                                                                        RAData[0]
                                                                            .role_id,
                                                                }
                                                            )}
                                                            <Input
                                                                value={
                                                                    RAData[0]
                                                                        .user
                                                                        .email
                                                                }
                                                                placeholder="Regional Admin"
                                                                disabled={
                                                                    !CaseValidation ||
                                                                    user.role ===
                                                                        "CT" ||
                                                                    user.role ===
                                                                        "CM" ||
                                                                    caseDetailsForm.getFieldValue(
                                                                        "category"
                                                                    ) ===
                                                                        "POSH" ||
                                                                    caseDetailsForm.getFieldValue(
                                                                        "category"
                                                                    ) ===
                                                                        "Special Cases"
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p>
                                                            No Regional Admin
                                                            data available
                                                        </p>
                                                    )}
                                                </Form.Item>
                                            )}
                                        </Col>
                                    ) : (
                                        <Col id="caseTroubleShooter" span={12}>
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    name="CaseTroubleShooter"
                                                    label="Troubleshooter"
                                                    className={styles.label}
                                                    rules={[
                                                        {
                                                            required:
                                                                CaseValidation &&
                                                                (user.role ===
                                                                    "CR" ||
                                                                    user.role ===
                                                                        "CM"),
                                                            message:
                                                                "Please enter the Troubleshooter",
                                                        },
                                                    ]}>
                                                    <Select
                                                        placeholder="Select Troubleshooter"
                                                        disabled={
                                                            user.role === "CM"
                                                                ? false
                                                                : true
                                                        }>
                                                        {TRBData?.length > 0
                                                            ? TRBData?.map(
                                                                  (
                                                                      item,
                                                                      index
                                                                  ) => {
                                                                      return (
                                                                          <Option
                                                                              key={
                                                                                  index
                                                                              }
                                                                              value={
                                                                                  item.role_id
                                                                              }>
                                                                              {
                                                                                  item
                                                                                      .user
                                                                                      .email
                                                                              }
                                                                          </Option>
                                                                      );
                                                                  }
                                                              )
                                                            : ""}
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                caseDatastate?.CaseTroubleShooter !==
                                                    null && (
                                                    <Row gutter={[16, 48]}>
                                                        <Col span={22}>
                                                            <CaseReportBlock
                                                                label="TroubleShooter"
                                                                displayMessage={
                                                                    <UserDisplayComp
                                                                        userid={
                                                                            caseDatastate[
                                                                                "CaseTroubleShooter"
                                                                            ]
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )}
                    {((user.role === "CM" &&
                        getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                            3) ||
                        (user.role === "CR" &&
                            getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                                2)) && (
                        <Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}>
                                <Popover
                                    content={
                                        <div>
                                            <p>
                                                All the filled information on
                                                the Case Report will get erased.
                                            </p>
                                        </div>
                                    }
                                    title="Clear All">
                                    <button
                                        id="clearAllButton"
                                        className="secondaryButton"
                                        onClick={(e) => {
                                            caseDetailsForm.resetFields();
                                            caseDetailsForm.setFieldsValue({
                                                category: null,
                                                priority: null,
                                            });
                                        }}>
                                        Clear All
                                    </button>
                                </Popover>
                                <Popover
                                    content={
                                        <div>
                                            <p>
                                                All the entered information will
                                                be saved in the case report.
                                            </p>
                                        </div>
                                    }
                                    title="Submit">
                                    {CaseValidation === false &&
                                    user.role === "CR" ? (
                                        <button
                                            onClick={() => {
                                                setAction("close");
                                                handleCloseInvalidCase();
                                            }}
                                            className="primaryButton">
                                            Close the Case
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => setAction("submit")}
                                            id="submitReportButton"
                                            htmltype="submit"
                                            className="primaryButton">
                                            Submit Report
                                        </button>
                                    )}
                                </Popover>
                            </div>
                        </Form.Item>
                    )}
                </Form>
            </div>
            <div
                style={{
                    position: "absolute",
                    left: "2.5rem",
                    bottom: ".1rem",
                }}>
                {((user.role === "CM" &&
                    getCounterFromCaseStatus(caseDatastate.CaseStatus) < 3) ||
                    (user.role === "CR" &&
                        getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                            2)) && (
                    <Popover
                        content={
                            <div>
                                <p>
                                    All the entered information will be saved as
                                    draft in the case report.
                                </p>
                            </div>
                        }
                        title="Draft">
                        <button
                            id="saveDraftButton"
                            onClick={() =>
                                debouncedHandleDraft(
                                    "caseDashboardSaveDraft",
                                    "Case Dashboard- Save draft"
                                )
                            }
                            className="secondaryButton">
                            Save Draft
                        </button>
                    </Popover>
                )}
            </div>
            <ActionModal
                isOpen={modalStates.showAutomationModal}
                onCancel={() => handleCancel("showAutomationModal")}
                title="Please Confirm"
                content="How would you like to proceed ahead with the case?"
                actions={actions}
            />
            <ConfirmationModal
                isOpen={modalStates.showConfirmationModal}
                onCancel={() => handleCancel("showConfirmationModal")}
                title="Please Confirm"
                content="Are you sure you want to Submit the case?"
                actions={{
                    confirm: {
                        handler: handleSubmission,
                    },
                }}
                width={400}
            />
        </>
    );
}
