// constants.js
export const GLOBAL_LINKS = {
    QC_SOP_LINK:
        "https://drive.google.com/file/d/1U9Y8uDeRroG1UMzCH2wWBpetqt7TO45Q/view", // SOP pdf link
    // Add more links as needed
};

export const predefinedLayouts = {
    english: {
        default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {shift}",
            ".com @ {space}",
        ],
    },
    hindi: {
        default: [
            " ऍ ॅ ्र र् ज्ञ त्र क्ष श्र ९ ० - ृ {bksp}",
            "{tab} ौ ै ा ी ू ब ह ग द ज ड ़ ॉ \\",
            "{lock} ो े ् ि ु प र क त च ट {enter}",
            "{shift} ं म न व ल स , . य {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ १ २ ३ ४ ५ ६ ७ ८ ९ ० ः ऋ {bksp}",
            "{tab} औ ऐ आ ई ऊ भ ङ घ ध झ ढ ञ ऑ",
            "{lock} ओ ए अ इ उ फ ऱ ख थ छ ठ {enter}",
            '{shift} "" ँ ण न व ळ श ष । य़ {shift}',
            ".! @ # ₹ % ^ & * ( ) _ + ",
        ],
    },
    kannada: {
        default: [
            "೧ ೨ ೩ ೪ ೫ ೬ ೭ ೮ ೯ ೦ - {bksp}",
            "{tab} ಕ ಖ ಗ ಘ ಙ ಚ ಛ ಜ ಝ ಞ [ ] \\",
            "{lock} ಟ ಠ ಡ ಢ ಣ ತ ಥ ದ ಧ ನ {enter}",
            "{shift} ಪ ಫ ಬ ಭ ಮ ಯ ರ ಲ ಶ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} ಶ್ರ ಹ ಅ ಆ ಇ ಈ ಉ ಊ ಋ ಎ ಐ |",
            "{lock} ಓ ಔ ಕ್ಷ ಷ ಸ ಹ < > ? {enter}",
            "{shift} ಯ ರ ಲ < > ? {shift}",
            ".com @ {space}",
        ],
    },
    telugu: {
        default: [
            "౧ ౨ ౩ ౪ ౫ ౬ ౭ ౮ ౯ ౦ - {bksp}",
            "{tab} క ఖ గ ఘ ఙ చ ఛ జ ఝ ఞ [ ] \\",
            "{lock} ట ఠ డ ఢ ణ త థ ద ధ న {enter}",
            "{shift} ప ఫ బ భ మ య ర ల శ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} శ్ర హ అ ఆ ఇ ఈ ఉ ఊ ఋ ఎ ఐ |",
            "{lock} ఓ ఔ క్ష ష స హ < > ? {enter}",
            "{shift} య ర ల < > ? {shift}",
            ".com @ {space}",
        ],
    },
    tamil: {
        default: [
            "` ௧ ௨ ௩ ௪ ௫ ௬ ௭ ௮ ௯ ௦ - = {bksp}",
            "{tab} அ ஆ இ ஈ உ ஊ எ ஏ ஐ ஒ ஓ ஔ \\",
            "{lock} க ங ச ஜ ஞ ட ண த ந ப ம {enter}",
            "{shift} ய ர ல வ ள ழ ற ன ஷ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} க்ஷ ஶ அ ஆ இ ஈ உ ஊ எ ஏ |",
            "{lock} ஓ ஔ ஸ ஹ ழ ற < > ? {enter}",
            "{shift} ய ர ல < > ? {shift}",
            ".com @ {space}",
        ],
    },
    oriya: {
        default: [
            "` ୧ ୨ ୩ ୪ ୫ ୬ ୭ ୮ ୯ ୦ - = {bksp}",
            "{tab} କ ଖ ଗ ଘ ଙ ଚ ଛ ଜ ଝ ଞ [ ] \\",
            "{lock} ଟ ଠ ଡ ଢ ଣ ତ ଥ ଦ ଧ ନ {enter}",
            "{shift} ପ ଫ ବ ଭ ମ ଯ ର ଲ ଶ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} ଶ୍ର ହ୍ ଅ ଆ ଇ ଈ ଉ ଊ ଋ ଏ ଐ |",
            "{lock} ଓ ଔ କ୍ ଖ୍ ଗ୍ ଘ୍ < > ? {enter}",
            "{shift} ଯ ର ଲ < > ? {shift}",
            ".com @ {space}",
        ],
    },
    bangla: {
        default: [
            "` ১ ২ ৩ ৪ ৫ ৬ ৭ ৮ ৯ ০ - = {bksp}",
            "{tab} ক খ গ ঘ ঙ চ ছ জ ঝ ঞ [ ] \\",
            "{lock} ট ঠ ড ঢ ণ ত থ দ ধ ন {enter}",
            "{shift} প ফ ব ভ ম য র ল শ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} শ্র হ অ আ ই ঈ উ ঊ ঋ এ ঐ |",
            "{lock} ও ঔ ক্ষ ষ স হ < > ? {enter}",
            "{shift} য র ল < > ? {shift}",
            ".com @ {space}",
        ],
    },
    punjabi: {
        default: [
            "` ੧ ੨ ੩ ੪ ੫ ੬ ੭ ੮ ੯ ੦ - = {bksp}",
            "{tab} ਕ ਖ ਗ ਘ ਙ ਚ ਛ ਜ ਝ ਞ [ ] \\",
            "{lock} ਟ ਠ ਡ ਢ ਣ ਤ ਥ ਦ ਧ ਨ {enter}",
            "{shift} ਪ ਫ ਬ ਭ ਮ ਯ ਰ ਲ ਸ਼ {shift}",
            ".com @ {space}",
        ],
        shift: [
            "~ ! @ # ₹ % ^ & * ( ) _ + {bksp}",
            "{tab} ਸ਼੍ਰ ਹ ਅ ਆ ਇ ਈ ਉ ਊ ਋ ਐ ਓ ਔ |",
            "{lock} ਕ਼ ਖ਼ ਗ਼ ਘ਼ ਙ਼ ਚ਼ ਛ਼ : {enter}",
            "{shift} ਯ ਰ ਲ ਸ ਸ਼਼ < > {shift}",
            ".com @ {space}",
        ],
    },
};
