// /* Table data based on user or factory admin dashboard depends on user.role*/
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./TableContainer.module.css";
import ButtonContainer from "./ButtonContainer";
import WelcomeHeading from "./WelcomeHeading";
import TableData from "./TableData";
import { useLocation } from "react-router";
import { Tour } from "antd";
import TourDescriptionModal from "../../QualityChecker/Modals/TourDescriptionModal";
import {
    getQCIntroViewed,
    updateQCIntroViewed,
} from "../../../Adapters/QCIntroCalls";
import { title, description } from "./IntroTourConstants";
import { RefContext } from "../../../App";
import { availableUserRoleStates } from "../../../RecoilState/GlobalUserRoleStates/availableUserRoleStates";
import { useRecoilState } from "recoil";

export default function TableContainer({ user }) {
    const [availableUserRoles, setAvailableUserRoles] = useRecoilState(
        availableUserRoleStates
    );
    const [currentStep, setCurrentStep] = useState(0);
    const [disable, setDisable] = useState(true);
    const [tourData, setTourData] = useState({
        open: false, // use to start the tour
        endTour: false, // if new case is present in the table
        flag: false, // use to open the case on click on begin at end of tour
        displayIntro: false, // if intro flow is viewed already
        showRole: false, // if multiple roles are shown
    });
    const location = useLocation();
    const introRef = useRef(null);
    const casesRef = useRef(null);
    const dateRef = useRef(null);
    const newRef = useRef(null);
    const approveRef = useRef(null);
    const reopenRef = useRef(null);
    const caseRowRef = useRef(null);
    const tourRef = {
        introRef,
        casesRef,
        dateRef,
        newRef,
        approveRef,
        reopenRef,
        caseRowRef,
    };
    const role = useContext(RefContext);

    useEffect(() => {
        if (user.role == "QC") {
            let tour = { ...tourData };
            if (availableUserRoles.length > 1) {
                tour.showRole = true;
            }
            getQCIntroViewed().then((res) => {
                tour.displayIntro = res?.message_body?.introFlow;
                if (res?.message_body?.introFlow) {
                    tour.open = true;
                }
                setTourData(tour);
            });
        }
    }, [user]);

    const keyTour = {
        mask: { textAlign: "left" },
        nextButtonProps: {
            children: <></>,
            style: {
                visibility: "hidden",
            },
        },
        prevButtonProps: {
            children: <></>,
            style: {
                visibility: "hidden",
            },
        },
    };

    useEffect(() => {
        if (currentStep == 7) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [currentStep]);

    useEffect(() => {
        const params = new URLSearchParams(location?.search);
        const critical = params.get("critical") === "true";
        localStorage.setItem("critical", critical);
    }, [location]);

    const handleTourFinish = () => {
        let tour = { ...tourData };
        if (!tourData.endTour) {
            tour.flag = true;
        }
        if (tourData.displayIntro) {
            updateQCIntroViewed(false)
                .then((res) => {
                    tour.endTour = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        tour.open = false;
        setTourData(tour);
        setCurrentStep(0);
    };

    const steps = [
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            target: () => tourRef.introRef.current,
            ...keyTour,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            target: () => tourRef.casesRef.current,
            ...keyTour,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            target: () => tourRef.dateRef.current,
            ...keyTour,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            target: () => tourRef.newRef.current,
            ...keyTour,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            target: () => tourRef.approveRef.current,
            ...keyTour,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                    showRole={tourData.showRole}
                />
            ),
            ...keyTour,
            target: () => tourRef.reopenRef.current,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={description[currentStep]}
                />
            ),
            ...keyTour,
            target: () => role.current,
        },
        {
            cover: (
                <TourDescriptionModal
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    title={title[currentStep]}
                    description={
                        tourData?.endTour
                            ? description[currentStep + 1]
                            : description[currentStep]
                    }
                    handleTourFinish={handleTourFinish}
                    endTour={tourData?.endTour}
                    showRole={tourData.showRole}
                />
            ),
            ...keyTour,
            target: () => tourRef.caseRowRef.current,
        },
    ];

    return (
        <div className={styles.TableContainer}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem 0 1rem 0",
                }}>
                <>
                    {user.role === "REGIONAL_ADMIN" &&
                    location.search === "?critical=true" ? (
                        <div className={styles.heading}>
                            <h1>POSH & Special cases</h1>
                        </div>
                    ) : (
                        <WelcomeHeading role={user.role}>
                            {user.name ? user.name : user.email}
                        </WelcomeHeading>
                    )}
                    <ButtonContainer
                        tourRef={tourRef}
                        setTourData={setTourData}
                    />
                </>
            </div>

            <TableData
                tourData={tourData}
                setTourData={setTourData}
                user={user}
                tourRef={tourRef}
            />
            {user.role == "QC" && (
                <>
                    <Tour
                        open={tourData.open}
                        steps={steps}
                        indicatorsRender={(current, total) => <></>}
                        current={currentStep}
                        closable={false}
                        disabledInteraction={disable}
                    />
                </>
            )}
        </div>
    );
}
