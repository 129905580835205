import { commonAxios } from "../utils/AxiosSettings";

export async function getCases(critical, activeTab, factoryIDs, company) {
    if (activeTab) {
        // check if IDs are received as an array or an integer
        const factories = Array.isArray(factoryIDs)
            ? factoryIDs.join(",")
            : factoryIDs;

        const queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/cases/`
        );
        //adding param based on type of data need to show
        queryUrl.searchParams.append("case_type", activeTab);
        queryUrl.searchParams.append("critical", critical);
        queryUrl.searchParams.append("factory", factories);

        const res = await commonAxios.get(queryUrl);
        return res;
    }
    return null;
}

export const downloadData = async (
    date,
    medium,
    factory,
    grievance,
    category
) => {
    const queryUrl = new URL(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/export_case_date/`
    );
    queryUrl.searchParams.append("start_date", date[0]);
    queryUrl.searchParams.append("end_date", date[1]);
    queryUrl.searchParams.append("Medium", JSON.stringify(medium));
    queryUrl.searchParams.append("Factory", JSON.stringify(factory));
    if (grievance) {
        queryUrl.searchParams.append(
            "Grievance_level",
            JSON.stringify(grievance)
        );
    }
    if (category) {
        queryUrl.searchParams.append("Category", JSON.stringify(category));
    }
    const res = await commonAxios.get(queryUrl, {
        responseType: "arraybuffer",
    });
    return res;
};
