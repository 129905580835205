import axios from "axios";

export async function TranslateLanguage(text, Source, Target) {
    const res = await axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATE_KEY}`,
        {
            q: text,
            source: Source,
            target: Target,
            format: "text",
        },
        { withCredentials: false } // 👈 Prevents CORS error
    );
    return res;
}
