import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Layout, message, Row } from "antd";

import CaseReport from "../../../components/CaseReport";
import styles from "../OfflineCases.module.css";
import { getOfflineCaseData } from "../../../Adapters/OfflineCasesCalls";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";

const OfflineCaseReport = () => {
    const [reportData, setReportData] = useState();
    const navigate = useNavigate();
    const { caseID } = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const [user] = useRecoilState(userState);

    const foreignKey =
        user?.user_role === "CR" ? user?.factory_fk : user?.company_fk;

    const location = useLocation();
    const state = location.state;

    const navToPage = () => {
        navigate("/home/OfflineCases", {
            state: {
                currentPage: state?.currentPage,
                activeTab: state?.activeTab,
            },
        });
    };

    // if state exists, then take id from state, otherwise from url params
    const caseReportID = state ? state.id : caseID;

    // function to fetch case report data
    const fetchReportData = async () => {
        try {
            const res = await getOfflineCaseData(caseReportID);
            setReportData(res?.data);
        } catch (error) {
            messageApi.open({
                type: "error",
                content: error.message.includes("403")
                    ? "Please go back and try again"
                    : error.message,
            });
        }
    };

    useEffect(() => {
        fetchReportData();
    }, [state, foreignKey]);

    const labels = ["General"];

    // schema or config for the labels in case report
    const dataFields = [
        // the key corresponds to the tabs, currently there are only 1 tab, but if needed, more can be added
        // type is for if a text area, label, or any other type has to be shown
        {
            key: "1",
            caseType: "In Person",
            fields: [
                {
                    name: "Issue Date",
                    dataIndex: "date",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Concerned Dpt.",
                    dataIndex: "concerned_dept",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Worker Name",
                    dataIndex: "worker_name",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Resolution Date",
                    dataIndex: "resolution_date",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Medium",
                    dataIndex: "medium",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Resolution Provided",
                    dataIndex: "resolution_provided",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "No. of Documents",
                    dataIndex: "supportive_documents_count",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Case Nature",
                    dataIndex: "case_nature",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Remarks",
                    dataIndex: "remarks",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "Category",
                    dataIndex: "category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Sub Category",
                    dataIndex: "sub_category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Case Details",
                    dataIndex: "case_details",
                    type: "text area",
                    position: "left",
                },
            ],
        },
        {
            key: "1",
            caseType: "Worker Committee",
            fields: [
                {
                    name: "Issue Date",
                    dataIndex: "date",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Concerned Dpt.",
                    dataIndex: "concerned_dept",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Committee Name",
                    dataIndex: "worker_committee",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Resolution Date",
                    dataIndex: "resolution_date",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Resolution Provided",
                    dataIndex: "resolution_provided",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "No. of Documents",
                    dataIndex: "supportive_documents_count",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Case Nature",
                    dataIndex: "case_nature",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Remarks",
                    dataIndex: "remarks",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "Category",
                    dataIndex: "category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Sub Category",
                    dataIndex: "sub_category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Case Details",
                    dataIndex: "case_details",
                    type: "text area",
                    position: "left",
                },
            ],
        },
        {
            key: "1",
            caseType: "Suggestion Box",
            fields: [
                {
                    name: "Issue Date",
                    dataIndex: "date",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Concerned Dpt.",
                    dataIndex: "concerned_dept",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Resolution Date",
                    dataIndex: "resolution_date",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Resolution Provided",
                    dataIndex: "resolution_provided",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "No. of Documents",
                    dataIndex: "supportive_documents_count",
                    type: "label",
                    position: "right",
                },
                {
                    name: "Case Nature",
                    dataIndex: "case_nature",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Remarks",
                    dataIndex: "remarks",
                    type: "text area",
                    position: "right",
                },
                {
                    name: "Category",
                    dataIndex: "category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Sub Category",
                    dataIndex: "sub_category",
                    type: "label",
                    position: "left",
                },
                {
                    name: "Case Details",
                    dataIndex: "case_details",
                    type: "text area",
                    position: "left",
                },
            ],
        },
    ];

    return (
        <LoggedInComponent>
            <Layout
                style={{
                    padding: "1rem",
                    backgroundColor: "#F4F7FE",
                }}>
                {contextHolder}
                <Row className={styles.caseReportRow}>
                    <div className={styles.headingContainer}>
                        <img
                            data-testid="go-back"
                            style={{ cursor: "pointer", marginRight: "1rem" }}
                            src="/assets/images/back/Group 4495.svg"
                            onClick={() => navToPage()}
                        />
                        <span className={styles.heading}>Case Report</span>
                    </div>
                </Row>
                <Row id="offlineBody" className={styles.caseReportBody}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <CaseReport
                            labels={labels}
                            dataFields={dataFields}
                            data={reportData}
                        />
                    </div>
                </Row>
            </Layout>
        </LoggedInComponent>
    );
};

export default OfflineCaseReport;
