import React, { useState } from "react";
import { Modal } from "antd";

import CustomButton from "../../Utils/CustomButton";

const UIDialog = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <CustomButton
                text="Export Data"
                colour="#FFFFFF"
                onClick={() => setIsOpen(true)}
            />
            <Modal
                className="modalStyle"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                width={510}
                maskClosable={false}
                footer={null}>
                {React.cloneElement(children, { closeModal })}
            </Modal>
        </>
    );
};

export default UIDialog;
