import React, { useState, useEffect } from "react";
import MultiTranslationDynamicVariable from "../../../../components/Utils/MultiTranslationDynamicVariable";
import { Row, Col, Spin, DatePicker, Modal, Button } from "antd";
import dayjs from "dayjs";

const Variables = ({
    variableData,
    getPreviewScreensDataHandler,
    setActiveTab,
    draftFlag,
    isTemplateValue,
    isLoading,
}) => {
    const [translatedDataObj, setTranslatedDataObj] = useState({});

    const [count, setCount] = useState(0);
    const [inputChangeFlag, setInputChangeFlag] = useState(true);
    const [countTraslatedMsg, setCountTranslatedMsg] = useState(0);
    const [dateValue, setDateValue] = useState(null);

    const onChangeHandler = (date, dateString, extraValue) => {
        setDateValue(date);

        // Create translations for the date based on available languages
        const dateTranslations = variableData[0]?.language?.reduce(
            (acc, lang) => {
                acc[lang] = dateString; // Use the same dateString for all languages
                return acc;
            },
            {}
        );

        setTranslatedDataObj((prevState) => ({
            ...prevState,
            [extraValue]: dateTranslations, // Add language-specific date translations
        }));
    };

    useEffect(() => {
        const item = variableData.find((data) => data.var.Date === "date");
        if (item) {
            const parsedDate = dayjs(item.InputValue, "YYYY-MM-DD");
            if (parsedDate.isValid()) {
                setDateValue(parsedDate);
                setTranslatedDataObj((prevState) => ({
                    ...prevState,
                    [Object.values(item.var)[0]]: item.InputValue,
                }));
            } else {
                setDateValue(null);
            }
        }
    }, [variableData]);

    return (
        <>
            {isLoading && <Spin />}
            <div
                style={
                    isLoading
                        ? { filter: "blur(5px)", pointerEvents: "none" }
                        : {}
                }>
                <div
                    style={{
                        minHeight: "50vh",
                        maxHeight: "50vh",
                        overflow: "hidden",
                        overflowY: "auto",
                        padding: "2rem 1rem",
                    }}>
                    {variableData?.length === 0
                        ? "There are no variable entries for this template"
                        : variableData?.map((item, i) => {
                              return (
                                  <>
                                      {Object.values(item.var)[0] === "date" ? (
                                          <>
                                              <label
                                                  name={
                                                      Object.values(item.var)[0]
                                                  }
                                                  htmlFor={
                                                      Object.values(item.var)[0]
                                                  }>
                                                  <Row
                                                      gutter={[48, 24]}
                                                      align="middle"
                                                      justify="start">
                                                      <Col>
                                                          <h3
                                                              style={{
                                                                  color: "#1b2559 ",
                                                                  fontSize:
                                                                      "16px",
                                                                  margin: "2rem 0",
                                                              }}>
                                                              {
                                                                  Object.keys(
                                                                      item.var
                                                                  )[0]
                                                              }
                                                              <span
                                                                  style={{
                                                                      color: "red",
                                                                  }}>
                                                                  *
                                                              </span>
                                                          </h3>
                                                      </Col>
                                                      <Col>
                                                          <DatePicker
                                                              value={
                                                                  dateValue
                                                                      ? dayjs(
                                                                            dateValue
                                                                        )
                                                                      : null
                                                              }
                                                              onChange={(
                                                                  date,
                                                                  dateString
                                                              ) =>
                                                                  onChangeHandler(
                                                                      date,
                                                                      dateString,
                                                                      "date"
                                                                  )
                                                              }
                                                              extra={
                                                                  Object.values(
                                                                      item.var
                                                                  )[0]
                                                              }
                                                          />
                                                      </Col>
                                                  </Row>
                                              </label>
                                          </>
                                      ) : (
                                          <MultiTranslationDynamicVariable
                                              key={i}
                                              item={item.var}
                                              language={item.language}
                                              userInputVar={variableData}
                                              setTranslatedDataObj={
                                                  setTranslatedDataObj
                                              }
                                              isTemplateValue={isTemplateValue}
                                              translatedDataObj={
                                                  translatedDataObj
                                              }
                                              prefilledInputValue={
                                                  item.InputValue
                                              }
                                              draftFlag={draftFlag}
                                              setCountTranslatedMsg={
                                                  setCountTranslatedMsg
                                              }
                                              setCount={setCount}
                                              count={count}
                                              setInputChangeFlag={
                                                  setInputChangeFlag
                                              }
                                              inputChangeFlag={inputChangeFlag}
                                          />
                                      )}
                                  </>
                              );
                          })}
                </div>

                <Row justify="end">
                    <Col>
                        <button
                            className="secondaryButton"
                            onClick={() => setActiveTab("1")}>
                            Back
                        </button>
                    </Col>
                    <Col>
                        <button
                            className="primaryButton"
                            onClick={() =>
                                getPreviewScreensDataHandler(translatedDataObj)
                            }>
                            Next
                        </button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Variables;
