/* Case Closing report */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ClosingState } from "../../../../RecoilState/ClosingState";
import styles from "../../CaseReport.module.css";
import { useRecoilState } from "recoil";
import {
    getCasereportData,
    getCheckForAutomationValue,
    makeCaseUnresponsiveCall,
} from "../../../../Adapters/CaseReportCalls";
import {
    Select,
    Input,
    Form,
    Divider,
    Row,
    Col,
    Popover,
    Spin,
    message,
} from "antd";
import CaseReportBlock from "../../../../components/Utils/CaseReportBlock";
import { getCounterFromCaseStatus } from "../../../../hooks/getCounterFromCaseStatus";
import {
    getSentMessagePreviewData,
    getTemplateData,
    postTemplateData,
} from "../../../../Adapters/SendMessage";
import MultiTranslationDynamicVariable from "../../../../components/Utils/MultiTranslationDynamicVariable";
import { SendMessageFlag } from "../../../../RecoilState/SendMessageFlag";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";
import { userState } from "../../../../RecoilState/userState";
import { tokenState } from "../../../../RecoilState/tokenState";
import ReplyFromWorker from "../../ReplyFromWorker";
import ResponseOfTheWorker from "../../ResponseOfTheWorker";
import { isEmpty } from "lodash";
import { QCCaseReportDataState } from "../../../../RecoilState/QCCaseReportDataState";
import { useLocation, useNavigate } from "react-router";
import UploadComponent from "../../../../components/UploadComponent/UploadHeaderFile";
import ActionModal from "../../../../components/Utils/ActionModal";

export default function CaseClosing({
    caseData,
    showModalClose,
    showModalDraft,
    showModalResolve,
    showClosingtheCaseModal,
    setIsReopenModalVisible,
    ResolveCaseHandler,
    // closingReportExistsFalse,
    // closingReportExistsTrue,
    setTranslatedDataObj,
    translatedDataObj,
    disableResolvingReport,
    isCTResolveCase,
    setRAFieldflagValues,
    selectedVersion,
    highlightedFields,
    handleUpload,
    setHandleUpload,
    fileList,
    setFileList,
    onUploadComplete,
}) {
    const [sendMessageFlag, setSendMessageFlag] =
        useRecoilState(SendMessageFlag);
    const [ClosingReport, setClosing] = useRecoilState(ClosingState);
    const [isLoading, setIsLoading] = useState(false);
    const [inputChangeFlag, setInputChangeFlag] = useState(true);
    const [previewScreenData, setPreviewScreenData] = useState();
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [variableData, setVariableData] = useState("");
    const [allTemplates, setAllTemplates] = useState({});
    const [language, setLanguage] = React.useState("");
    const [template, setTemplate] = React.useState("");
    const [templateID, setTemplateID] = useState("");
    const [prefilledInputValue, setPrefilledInputValue] = useState("");
    const [draftFlag, setDraftFlag] = useState(false);
    const [ResolvingReportData, setResolvingReportData] = useState("");
    //states to handel show message only when all the input field varible are translated
    const [count, setCount] = useState(0);
    const [isTemplateValue, setIsTemplateValue] = useState(false);
    const [countTraslatedMsg, setCountTranslatedMsg] = useState(0);
    const [caseClosingForm] = Form.useForm();
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [selectedValue, setSelectedValue] = useState(null);
    const [QCcaseDatastate, setQCcaseDatastate] = useRecoilState(
        QCCaseReportDataState
    );
    const { state } = useLocation();
    const from = state?.myData;
    const storedCriticalValue = localStorage.getItem("critical") === "true";
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [modalStates, setModalStates] = useState({
        showAutomationModal: false,
    });
    const [updatePreview, setUpdatePreview] = useState(false);
    let navigate = useNavigate();
    const allowedFileTypes = [
        "jpeg",
        "jpg",
        "png",
        "svg",
        "pdf",
        "csv",
        "pps",
        "ppt",
        "pptx",
        "txt",
        "xls",
        "xlsx",
        "xlsm",
        "gif",
        "doc",
        "docx",
        "tex",
        "rtf",
        "mp4",
        "mpg",
        "mpeg",
        "mov",
        "avi",
        "log",
        "aif",
        "mp3",
        "mpa",
        "wav",
        "wma",
    ];
    const refreshFile = () => {
        getCasereportData(caseData.id).then((res) => {
            setClosing((prevState) => ({
                ...prevState, // Keep all existing form data and state
                CCRDocument: res.message_body.CCRDocument, // Only update the File data from the response
            }));
            setResolvingReportData(res);
        });
    };
    const prevDeps = useRef({});
    const prevDepsVar = useRef({});
    const getVariableData = () => {
        const id =
            template &&
            language &&
            Object.keys(allTemplates).length > 0 &&
            allTemplates.hasOwnProperty(template) &&
            allTemplates[template][language];
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template/`
        );
        setTemplateID(id);
        queryUrl.searchParams.append("templateID", id);
        queryUrl.searchParams.append("caseID", caseData?.id);
        queryUrl.searchParams.append("language", language);
        try {
            postTemplateData(queryUrl).then((res) => {
                setVariableData(res?.data?.message_body);
            });
        } catch (error) {
            console.log("error in res");
        }
    };
    const getPreviewScreensDataHandler = async (translatedDataObj) => {
        setIsLoading(true);
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/varmap/`
        );
        let objectString = JSON.stringify(translatedDataObj);

        queryUrl.searchParams.append("inputs", objectString);
        try {
            const res = await getSentMessagePreviewData(queryUrl).catch(
                (error) => message.error(error?.response?.data?.errorMessage)
            );

            setPreviewScreenData(res?.data.message_body[0]);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const handleCaseClosingSubmission = (eventName, actionType) => {
        setClosing({
            ...ClosingReport,
            when: caseClosingForm.getFieldValue("when"),
            who: caseClosingForm.getFieldValue("who"),
            where: caseClosingForm.getFieldValue("where"),
            what: caseClosingForm.getFieldValue("what"),
            remarks: caseClosingForm.getFieldValue("remarks"),
            CCRComments_RA: caseClosingForm.getFieldValue("CCRComments_RA"),
            action: caseClosingForm.getFieldValue("action"),
            isResolved: caseClosingForm.getFieldValue("isResolved"),
        });
    };

    const handleCaseClosingDraft = (eventName, actionType) => {
        setClosing({
            ...ClosingReport,
            when: caseClosingForm.getFieldValue("when"),
            who: caseClosingForm.getFieldValue("who"),
            where: caseClosingForm.getFieldValue("where"),
            what: caseClosingForm.getFieldValue("what"),
            remarks: caseClosingForm.getFieldValue("remarks"),
            CCRComments_RA: caseClosingForm.getFieldValue("CCRComments_RA"),
            action: caseClosingForm.getFieldValue("action"),
            isResolved: caseClosingForm.getFieldValue("isResolved"),
        });
    };

    // Function to check if a field should be highlighted
    const isFieldHighlighted = (fieldName) => {
        // Adjust the logic based on how you want to highlight (Exception, Prefilled, etc.)
        return (
            highlightedFields?.Exception.includes(fieldName) ||
            highlightedFields?.Prefilled.includes(fieldName)
        );
    };
    useEffect(() => {
        if (ResolvingReportData?.message_body?.CCRDocument?.length >= 0) {
            setUploadedFiles(ResolvingReportData?.message_body?.CCRDocument);
        }
    }, [ResolvingReportData]);

    useEffect(() => {
        setCountTranslatedMsg(0);
    }, [template]);

    useEffect(() => {
        const currentDeps = {
            template,
            language,
            previewScreenDataBody: previewScreenData?.body,
            caseData,
            allTemplates,
            sendMessageFlag,
            translatedDataObj,
        };

        const depsChanged = Object.keys(currentDeps).some(
            (key) => currentDeps[key] !== prevDeps.current[key]
        );

        if (depsChanged) {
            if (template && language && Object.keys(allTemplates).length > 0) {
                getVariableData();
            }
            setClosing({
                ...ClosingReport,
                template: template,
                language: language,
                message: previewScreenData?.body
                    ? previewScreenData?.body
                    : ClosingReport.message,
                templateID: templateID,
            });
            prevDeps.current = currentDeps;
        }
    }, [
        template,
        language,
        previewScreenData?.body,
        // ClosingReport.message,
        caseData,
        allTemplates,
        sendMessageFlag,
        translatedDataObj,
    ]);

    useEffect(() => {
        if (ResolvingReportData) {
            setClosing({
                when: ResolvingReportData?.message_body?.CCRWhen,
                where: ResolvingReportData?.message_body?.CCRWhere,
                remarks: ResolvingReportData?.message_body?.CCRremarks,
                who: ResolvingReportData?.message_body?.CCRWho,
                what: ResolvingReportData?.message_body?.CCRWhathappened,
                template: ResolvingReportData?.message_body?.CCTemplate,
                language: ResolvingReportData?.message_body?.CCLanguage,
                message: ResolvingReportData?.message_body?.CCMessage,
                templateID: ResolvingReportData?.message_body?.CCTemplateID,
                action: ResolvingReportData.message_body?.CCRAction,
                CCRComments_RA:
                    ResolvingReportData?.message_body?.CCRComments_RA,
                workerResponse:
                    ResolvingReportData?.message_body?.workerResponse,
                isResolved: ResolvingReportData?.message_body?.CCRisResolved,
                CCRDocument: ResolvingReportData?.message_body?.CCRDocument,
            });
        }
    }, [ResolvingReportData]);

    useEffect(() => {
        getCasereportData(from?.id, selectedVersion)
            .then((res) => {
                setResolvingReportData(res);
                // if the case is Under Investigation, set the local ClosingReport to empty rather than the previous data
                if (
                    getCounterFromCaseStatus(caseData.CaseStatus) === 5 &&
                    caseData.reopened &&
                    caseData.CurrentStatus !== "Draft"
                ) {
                    setClosing({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                        workerResponse: "",
                        action: "",
                        isResolved: "",
                        CCRDocument: [],
                    });
                    caseClosingForm.setFieldsValue({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                        workerResponse: "",
                        action: "",
                        isResolved: "",
                        CCRDocument: [],
                    });
                    // closingReportExistsTrue(true);
                } else {
                    setClosing({
                        when: res?.message_body?.CCRWhen,
                        where: res?.message_body?.CCRWhere,
                        remarks: res?.message_body?.CCRremarks,
                        who: res?.message_body?.CCRWho,
                        what: res?.message_body?.CCRWhathappened,
                        template: res?.message_body?.CCTemplate,
                        language: res?.message_body?.CCLanguage,
                        message: res?.message_body?.CCMessage,
                        templateID: res?.message_body?.CCTemplateID,
                        CCRComments_RA: res?.message_body?.CCRComments_RA,
                        workerResponse: res?.message_body?.workerResponse,
                        action: res.message_body?.CCRAction,
                        isResolved: res?.message_body?.CCRisResolved,
                        CCRDocument: res?.message_body?.CCRDocument,
                    });

                    setLanguage(res?.message_body?.CCLanguage);
                    setTemplate(res?.message_body?.CCTemplate);
                    setPrefilledInputValue(res?.message_body?.variables);

                    caseClosingForm.setFieldsValue({
                        when: res?.message_body?.CCRWhen,
                        where: res?.message_body?.CCRWhere,
                        remarks: res?.message_body?.CCRremarks,
                        who: res?.message_body?.CCRWho,
                        what: res?.message_body?.CCRWhathappened,
                        template: res?.message_body?.CCTemplate,
                        language: res?.message_body?.CCLanguage,
                        message: res?.message_body?.CCMessage,
                        CCRComments_RA: res?.message_body?.CCRComments_RA,
                        workerResponse: res?.message_body?.workerResponse,
                        action: res.message_body?.CCRAction,
                        isResolved: res?.message_body?.CCRisResolved,
                        CCRDocument: res?.message_body?.CCRDocument,
                    });
                    // closingReportExistsTrue(true);
                }
            })
            .catch((err) => {
                if (err?.response?.status === 404) {
                    setClosing({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                        workerResponse: "",
                        action: "",
                        isResolved: "",
                    });
                    caseClosingForm.setFieldsValue({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                        workerResponse: "",
                        action: "",
                        isResolved: "",
                    });
                    // closingReportExistsFalse(false);
                }
            });
    }, [caseData.Company, token, selectedVersion]); // caseData contains id, Company and CaseStatus properties. It should be used as a dependency in useEffect to avoid stale data.
    const onFormLayoutChange = (values) => {
        if ("template" in values) {
            setTemplate(values.template);
            setIsTemplateValue(!isTemplateValue);
        } else if ("language" in values) {
            setLanguage(values.language);
        }
    };

    useEffect(() => {
        caseData.CurrentStatus === "Draft"
            ? setDraftFlag(true)
            : setDraftFlag(false);
        if (
            caseData.Complainer &&
            getCounterFromCaseStatus(caseData.CaseStatus) <= 5
        ) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template`
            );

            queryUrl.searchParams.append("companyID", caseData.Company);
            //setting the template category as send message for testing replace it to Resolving Message
            if (
                user.role === "REGIONAL_ADMIN" &&
                caseData?.Category?.workflow === "Poshratroubleshootflow"
            )
                queryUrl.searchParams.append("temp_category", [
                    "Posh Message",
                    "Posh Unresponsive Message",
                ]);
            else if (
                user.role === "REGIONAL_ADMIN" &&
                caseData?.Category?.workflow === "Ratroubleshootflow"
            ) {
                queryUrl.searchParams.append("temp_category", [
                    "Resolving Message",
                    "Unresponsive Message",
                ]);
            } else {
                queryUrl.searchParams.append(
                    "temp_category",
                    "Resolving Message"
                );
            }
            try {
                getTemplateData(queryUrl)
                    .then((res) => {
                        setAllTemplates(res?.data.message_body?.templates);
                    })
                    .catch((error) => {
                        message.error(error.response.data.errorMessage);
                    });
            } catch (error) {
                alert("error in response retry again");
            }
        }
    }, [caseData]);

    useEffect(() => {
        const template = caseClosingForm.getFieldValue("template");

        const templateFlags = {
            "Lack of response": {
                CBRRMandatory: false,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH case closure": {
                CBRRMandatory: true,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH case closure (No response)": {
                CBRRMandatory: false,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH Lack of response": {
                CBRRMandatory: true,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            default: {
                CBRRMandatory: false,
                closeModalFlag: false,
                closingRemarkMandatory: true,
            },
        };

        setRAFieldflagValues((prevState) => ({
            ...prevState,
            ...(templateFlags[template] || templateFlags.default),
        }));
    }, [caseClosingForm.getFieldValue("template")]);

    useEffect(() => {
        setCount(0);
        setTranslatedDataObj({});
    }, [isTemplateValue]);

    useEffect(() => {
        setTranslatedDataObj({});
    }, [templateID, language]);

    useEffect(() => {
        const currentDeps = {
            count,
            translatedDataObj,
            variableData,
            isTemplateValue,
            language,
            inputChangeFlag,
        };

        const depsChanged = Object.keys(currentDeps).some(
            (key) => currentDeps[key] !== prevDepsVar.current[key]
        );

        if (depsChanged) {
            if (variableData !== "") {
                if (
                    count === Object.keys(variableData).length &&
                    Object.keys(translatedDataObj).length === count
                ) {
                    getPreviewScreensDataHandler(translatedDataObj);
                }
            }
            prevDepsVar.current = currentDeps;
        }
    }, [
        count,
        translatedDataObj,
        variableData,
        isTemplateValue,
        language,
        inputChangeFlag,
    ]);
    function checkPermissionForInputFields(role) {
        const counter = getCounterFromCaseStatus(caseData.CaseStatus);

        switch (role) {
            case "CT":
                return counter <= 5;
            case "REGIONAL_ADMIN":
                return counter <= 5;
            default:
                return false;
        }
    }
    function checkPermissionForHideFields(role) {
        const counter = getCounterFromCaseStatus(caseData.CaseStatus);

        switch (role) {
            case "CR":
            case "CM":
            case "SUPER_ADMIN":
                return counter <= 5;
            case "LEAD_SUPERVISOR":
                return counter <= 5;
            case "REGIONAL_ADMIN":
                if (storedCriticalValue) {
                    return (
                        (counter <= 5 &&
                            caseData?.Category?.workflow !==
                                "Poshratroubleshootflow" &&
                            caseData?.Category?.workflow !==
                                "Ratroubleshootflow") ||
                        (counter < 3 &&
                            (caseData?.Category?.workflow ===
                                "Poshratroubleshootflow" ||
                                caseData?.Category?.workflow ===
                                    "Ratroubleshootflow"))
                    );
                } else {
                    return counter <= 5;
                }
            default:
                return false;
        }
    }

    const handleCancel = (modalName) => {
        setModalStates((prevState) => ({
            ...prevState,
            [modalName]: false,
        }));
    };
    const markCaseUnresponsive = () => {
        const res = makeCaseUnresponsiveCall(caseData?.id);
        setModalStates((prevState) => ({
            ...prevState,
            showAutomationModal: false,
        }));
        navigate(previousRoute.defaultRoute);
    };

    const actions = [
        {
            text: "Case will be moved to archive, if you haven’t received any reply within the timeline.",
            buttonText: "Mark Unresponsive",
            handler: markCaseUnresponsive,
        },
        {
            text: "Take action to resolve this case.",
            buttonText: "Resolve the case",
            handler: ResolveCaseHandler,
        },
    ];
    const submitHandler = async () => {
        try {
            // If the complainer is not null and the user is not regional admin, then only we will check for automation
            if (
                caseData?.Complainer !== null &&
                user.role !== "REGIONAL_ADMIN"
            ) {
                const res = await getCheckForAutomationValue(caseData?.id);
                if (res.data.showConfirmation === true) {
                    setModalStates((prevState) => ({
                        ...prevState,
                        showAutomationModal: true,
                    }));
                } else if (res.data.showConfirmation === false) {
                    showModalClose();
                }
            } else {
                showModalClose();
            }
        } catch (error) {
            if (error.response.status === 400) {
                message.error(error.response.data.errorMessage);
            } else {
                message.error(
                    error.response && error.response.data.errorMessage
                        ? error.response.data.errorMessage
                        : "An error occurred while checking for automation."
                );
            }
        }
    };
    const shouldDeleteDocument = () => {
        const caseStatusCounter = getCounterFromCaseStatus(caseData.CaseStatus);
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseData?.Category?.workflow !== "Poshratroubleshootflow" &&
                caseData?.Category?.workflow !== "Ratroubleshootflow") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };

    const shouldDownloadDocument = () => {
        const caseStatusCounter = getCounterFromCaseStatus(caseData.CaseStatus);
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseData?.Category?.workflow !== "Poshratroubleshootflow" &&
                caseData?.Category?.workflow !== "Ratroubleshootflow") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };

    const shouldDisableUpload = () => {
        const caseStatusCounter = getCounterFromCaseStatus(caseData.CaseStatus);
        return (
            (caseStatusCounter > 3 &&
                !(
                    (caseStatusCounter === 5 || caseStatusCounter === 4) &&
                    (user.role === "CT" || user.role === "REGIONAL_ADMIN")
                )) ||
            (caseStatusCounter >= 2 && user.role === "CR") ||
            user.role === "SUPER_ADMIN" ||
            user.role == "FACTORY_ADMIN" ||
            user.role === "LEAD_SUPERVISOR" ||
            (user.role === "REGIONAL_ADMIN" &&
                caseData?.Category?.workflow !== "Poshratroubleshootflow" &&
                caseData?.Category?.workflow !== "Ratroubleshootflow") ||
            (user.role === "CM" && caseStatusCounter > 2)
        );
    };

    return (
        caseData.CaseValidation === true && (
            <div style={{ position: "relative" }}>
                {/* <Divider style={{ background: "#a3aed0" }} /> */}
                {checkPermissionForHideFields(user.role) ? null : (
                    <>
                        <div className={styles.caseReportHeading}>
                            {user.role === "REGIONAL_ADMIN" &&
                            caseData?.Category?.workflow ===
                                "Poshratroubleshootflow"
                                ? "Remarks"
                                : "Case Resolving Report"}
                        </div>

                        <Form
                            name="Case Closing Form"
                            form={caseClosingForm}
                            onValuesChange={onFormLayoutChange}
                            onFormFinish={() => handleCaseClosingSubmission()}>
                            {checkPermissionForHideFields(
                                user.role
                            ) ? null : caseData?.Category?.workflow ===
                              "Poshratroubleshootflow" ? (
                                <Row
                                    gutter={[16, 48]}
                                    className={styles.topPadding}>
                                    <Col
                                        span={12}
                                        style={{ paddingRight: "2rem" }}>
                                        {checkPermissionForInputFields(
                                            user.role
                                        ) ? (
                                            <Form.Item
                                                label="Comment by Regional Admin"
                                                name="CCRComments_RA"
                                                rules={[
                                                    {
                                                        required:
                                                            caseClosingForm.getFieldValue(
                                                                "template"
                                                            ) !==
                                                                "Lack of response" &&
                                                            caseClosingForm.getFieldValue(
                                                                "template"
                                                            ) !==
                                                                "POSH case closure (No response)",
                                                        message:
                                                            "Please enter Comment by Regional Admin",
                                                    },
                                                ]}
                                                initialValue={
                                                    ClosingReport.CCRComments_RA
                                                }>
                                                <Input.TextArea
                                                    name="CCRComments_RA"
                                                    readOnly={
                                                        getCounterFromCaseStatus(
                                                            caseData.CaseStatus
                                                        ) >= 6
                                                    }
                                                    rows="4"
                                                    cols="50"
                                                    maxLength={5000}
                                                    showCount
                                                />
                                            </Form.Item>
                                        ) : (
                                            <CaseReportBlock
                                                label="Comment by Regional Admin"
                                                displayMessage={
                                                    ClosingReport.CCRComments_RA
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Row
                                        gutter={[16, 48]}
                                        className={styles.topPadding}>
                                        <Col
                                            id="whenCT"
                                            span={12}
                                            style={{ paddingRight: "2rem" }}>
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="Date Time and Location of the event"
                                                    name="when"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter Date Time and Location of the event",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.when
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRWhen"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        name="Date Time and Location of the event"
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        rows="4"
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="Date Time and Location of the event"
                                                    displayMessage={
                                                        ClosingReport.when
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col span={12} id="whatCT">
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="What led to the problem"
                                                    name="what"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter What led to the problem",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.what
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRWhathappened"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        name="what"
                                                        rows="4"
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="What led to the problem"
                                                    displayMessage={
                                                        ClosingReport.what
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 48]}>
                                        <Col
                                            id="actionCT"
                                            span={12}
                                            style={{ paddingRight: "2rem" }}>
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="Action taken for the resolution"
                                                    name="action"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter Action taken for the resolution",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.action
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRWhere"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        name="action"
                                                        rows="4"
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="Action taken for the resolution"
                                                    displayMessage={
                                                        ClosingReport.action
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col span={12} id="whoCT">
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="Department Name/ Person involved"
                                                    name="who"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter Department Name/ Person involved",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.who
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRWho"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        name="who"
                                                        rows="4"
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="Department Name/ Person involved"
                                                    displayMessage={
                                                        ClosingReport.who
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>

                                    <Row gutter={[16, 48]}>
                                        <Col
                                            span={12}
                                            style={{ paddingRight: "2rem" }}>
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="Confirm if the issue is resolved"
                                                    name="isResolved"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter Confirm if the issue is resolved",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.isResolved
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRremarks"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        name="isResolved"
                                                        rows="4"
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="Confirm if the issue is resolved"
                                                    displayMessage={
                                                        ClosingReport.isResolved
                                                    }
                                                />
                                            )}
                                            {checkPermissionForInputFields(
                                                user.role
                                            ) ? (
                                                <Form.Item
                                                    label="Closing Remark"
                                                    name="remarks"
                                                    rules={[
                                                        {
                                                            required:
                                                                caseClosingForm.getFieldValue(
                                                                    "template"
                                                                ) !==
                                                                "Lack of response",
                                                            message:
                                                                "Please enter the Closing Remark",
                                                        },
                                                    ]}
                                                    initialValue={
                                                        ClosingReport.remarks
                                                    }
                                                    validateStatus={
                                                        isFieldHighlighted(
                                                            "CCRremarks"
                                                        )
                                                            ? "error"
                                                            : ""
                                                    }>
                                                    <Input.TextArea
                                                        name="remarks"
                                                        rows="4"
                                                        readOnly={
                                                            getCounterFromCaseStatus(
                                                                caseData.CaseStatus
                                                            ) >= 6
                                                        }
                                                        cols="50"
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <CaseReportBlock
                                                    label="Closing Remark"
                                                    displayMessage={
                                                        ClosingReport.remarks
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col
                                            span={12}
                                            style={{ paddingRight: "2rem" }}>
                                            <Form.Item
                                                name="Document"
                                                label="Document"
                                                className={styles.label}>
                                                <UploadComponent
                                                    document_delete_on={
                                                        !shouldDeleteDocument()
                                                    }
                                                    document_download_on={
                                                        !shouldDownloadDocument()
                                                    }
                                                    disableUpload={shouldDisableUpload()}
                                                    externalFileList={fileList}
                                                    onExternalFileListChange={(
                                                        newFileList
                                                    ) =>
                                                        setFileList(newFileList)
                                                    }
                                                    Files={uploadedFiles}
                                                    maxFiles="7"
                                                    companyFk={user.company_fk}
                                                    model="resolving_report"
                                                    allowedFileTypes={
                                                        allowedFileTypes
                                                    }
                                                    modelMethod="new"
                                                    scrollable={true}
                                                    onModelCreated={
                                                        handleUpload
                                                    }
                                                    setOnModalCreated={
                                                        setHandleUpload
                                                    }
                                                    id={caseData.id}
                                                    onUploadComplete={
                                                        onUploadComplete
                                                    }
                                                    refresh={refreshFile}
                                                    showAsANamedHyperLink={
                                                        false
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Divider style={{ background: "#a3aed0" }} />
                            {/* CT message template box is conditional on availability of phone number(caseData.Complainer)  */}
                            {caseData.Complainer ? (
                                <>
                                    <div className={styles.remarksBottom}>
                                        <div
                                            id="remarkCTSMSTemplate"
                                            className={
                                                styles.caseReportHeading
                                            }>
                                            {user.role === "REGIONAL_ADMIN" &&
                                            caseData?.Category?.workflow ===
                                                "Poshratroubleshootflow"
                                                ? "Closing Remarks Message"
                                                : "Resolving Remarks Message"}
                                        </div>
                                        <div className={styles.closingMainBox}>
                                            {getCounterFromCaseStatus(
                                                caseData.CaseStatus
                                            ) >= 6 ? null : (
                                                <>
                                                    <div
                                                        className={
                                                            styles.headingWrapper
                                                        }>
                                                        01- Basics <hr />
                                                    </div>
                                                    <hr />
                                                </>
                                            )}
                                            <Row>
                                                <Col span={12}>
                                                    <div
                                                        id="closing-remarks-message-template-box"
                                                        className=""
                                                        style={{
                                                            paddingRight:
                                                                "2rem",
                                                        }}>
                                                        {getCounterFromCaseStatus(
                                                            caseData.CaseStatus
                                                        ) >= 6 ? (
                                                            <CaseReportBlock
                                                                label="Template"
                                                                displayMessage={
                                                                    ClosingReport.template
                                                                }
                                                            />
                                                        ) : (
                                                            <Form.Item
                                                                label={
                                                                    <label
                                                                        className={
                                                                            styles.label
                                                                        }>
                                                                        Template
                                                                    </label>
                                                                }
                                                                initialValue={
                                                                    ClosingReport.template
                                                                }
                                                                name="template"
                                                                className={
                                                                    styles.lable
                                                                }
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Please select Template!",
                                                                    },
                                                                ]}>
                                                                <Select>
                                                                    {Object.keys(
                                                                        allTemplates
                                                                    )?.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    value={
                                                                                        item
                                                                                    }>
                                                                                    {
                                                                                        item
                                                                                    }
                                                                                </Select.Option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div id="closing-remarks-message-language">
                                                        {getCounterFromCaseStatus(
                                                            caseData.CaseStatus
                                                        ) >= 6 ? (
                                                            <>
                                                                <CaseReportBlock
                                                                    label="Language"
                                                                    displayMessage={
                                                                        ClosingReport.language
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <Form.Item
                                                                label={
                                                                    <label
                                                                        className={
                                                                            styles.label
                                                                        }>
                                                                        Language
                                                                    </label>
                                                                }
                                                                initialValue={
                                                                    ClosingReport.language
                                                                }
                                                                className={
                                                                    styles.lable
                                                                }
                                                                name="language"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Please select Language!",
                                                                    },
                                                                ]}>
                                                                <Select>
                                                                    {Object.keys(
                                                                        allTemplates
                                                                    ).length >
                                                                        0 &&
                                                                        template &&
                                                                        allTemplates.hasOwnProperty(
                                                                            template
                                                                        ) &&
                                                                        Object.keys(
                                                                            allTemplates[
                                                                                template
                                                                            ]
                                                                        ).map(
                                                                            (
                                                                                val
                                                                            ) => (
                                                                                <Select.Option
                                                                                    key={
                                                                                        val
                                                                                    }
                                                                                    value={
                                                                                        val
                                                                                    }>
                                                                                    {
                                                                                        val
                                                                                    }
                                                                                </Select.Option>
                                                                            )
                                                                        )}
                                                                </Select>
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6 ? null : (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.headingWrapper
                                                            }>
                                                            02- Variables
                                                            <hr />
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.variableBox
                                                            }>
                                                            {variableData === ""
                                                                ? null
                                                                : Object.keys(
                                                                      variableData
                                                                  ).length === 0
                                                                ? "There are no variable entries for this template"
                                                                : variableData?.map(
                                                                      (
                                                                          item,
                                                                          i
                                                                      ) => {
                                                                          return (
                                                                              <>
                                                                                  <MultiTranslationDynamicVariable
                                                                                      key={
                                                                                          item.var
                                                                                      }
                                                                                      item={
                                                                                          item.var
                                                                                      }
                                                                                      language={
                                                                                          item.language
                                                                                      }
                                                                                      userInputVar={
                                                                                          variableData
                                                                                      }
                                                                                      setTranslatedDataObj={
                                                                                          setTranslatedDataObj
                                                                                      }
                                                                                      translatedDataObj={
                                                                                          translatedDataObj
                                                                                      }
                                                                                      prefilledInputValue={
                                                                                          draftFlag &&
                                                                                          prefilledInputValue &&
                                                                                          prefilledInputValue[0]
                                                                                              ?.InputValue
                                                                                      }
                                                                                      isTemplateValue={
                                                                                          isTemplateValue
                                                                                      }
                                                                                      setCountTranslatedMsg={
                                                                                          setCountTranslatedMsg
                                                                                      }
                                                                                      setCount={
                                                                                          setCount
                                                                                      }
                                                                                      count={
                                                                                          count
                                                                                      }
                                                                                      setInputChangeFlag={
                                                                                          setInputChangeFlag
                                                                                      }
                                                                                      inputChangeFlag={
                                                                                          inputChangeFlag
                                                                                      }
                                                                                      draftFlag={
                                                                                          draftFlag
                                                                                      }
                                                                                  />
                                                                              </>
                                                                          );
                                                                      }
                                                                  )}
                                                        </div>
                                                    </>
                                                )}
                                            </Row>
                                            <Row>
                                                {getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6 ? (
                                                    <Row gutter={[16, 48]}>
                                                        <Col span={12}>
                                                            <CaseReportBlock
                                                                label="Message"
                                                                displayMessage={
                                                                    ClosingReport.message
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.headingWrapper
                                                        }>
                                                        <p>03 - Message</p>
                                                        <hr />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}>
                                                            {isLoading && (
                                                                <Spin />
                                                            )}
                                                        </div>

                                                        {/* to show message only when all the input field are filled and all the input message are translated */}
                                                        {count ===
                                                        Object.keys(
                                                            variableData
                                                        ).length ? (
                                                            <div
                                                                style={
                                                                    isLoading
                                                                        ? {
                                                                              filter: "blur(5px)",
                                                                              pointerEvents:
                                                                                  "none",
                                                                          }
                                                                        : {}
                                                                }
                                                                className={
                                                                    styles.messageBox
                                                                }>
                                                                {
                                                                    previewScreenData?.body
                                                                }
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                    <Divider
                                        style={{ background: "#a3aed0" }}
                                    />
                                </>
                            ) : null}
                            {/*  isCTResolveCase prop make sure to show these Component in CT resolve cases only, addtion this will be visible to thr case which are resolved*/}
                            {isCTResolveCase &&
                                !isEmpty(QCcaseDatastate?.reply) &&
                                getCounterFromCaseStatus(caseData.CaseStatus) >=
                                    6 && (
                                    <>
                                        <div
                                            className={
                                                styles.caseReportHeading
                                            }>
                                            Reply from the Worker
                                        </div>
                                        <ReplyFromWorker
                                            caseData={QCcaseDatastate?.reply}
                                        />
                                        <Divider
                                            style={{ background: "#a3aed0" }}
                                        />
                                        {/* we are not shoeing ResponseOfTheWorker in SA Dashboardcase report if workerResponse is empty at all */}
                                        {(user.role === "SUPER_ADMIN" ||
                                            user.role === "LEAD_SUPERVISOR") &&
                                        ClosingReport?.workerResponse ===
                                            "" ? null : (
                                            <>
                                                <ResponseOfTheWorker
                                                    selectedValue={
                                                        selectedValue
                                                    }
                                                    setSelectedValue={
                                                        setSelectedValue
                                                    }
                                                    workerResponse={
                                                        ClosingReport?.workerResponse
                                                    }
                                                    readOnly={
                                                        getCounterFromCaseStatus(
                                                            caseData.CaseStatus
                                                        ) === 6 &&
                                                        user.role === "CT"
                                                    }
                                                />
                                                <Divider
                                                    style={{
                                                        background: "#a3aed0",
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            <Row align="middle" justify="end">
                                <Col>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) < 6 && (
                                        <Popover
                                            content={
                                                <div>
                                                    <p>
                                                        All the filled
                                                        information on the Case
                                                        Report will get erased.
                                                    </p>
                                                </div>
                                            }
                                            title="Clear All">
                                            {/* disableResolvingReport prop make sure that at what condition we want to diable the resolving report */}
                                            <button
                                                id="clearAllCTForm"
                                                disabled={
                                                    disableResolvingReport
                                                }
                                                className={
                                                    disableResolvingReport
                                                        ? styles.disabledBtn
                                                        : "secondaryButton"
                                                }
                                                onClick={() => {
                                                    caseClosingForm.resetFields();
                                                }}>
                                                Clear All
                                            </button>
                                        </Popover>
                                    )}
                                </Col>
                                <Col>
                                    {checkPermissionForInputFields(user.role) &&
                                        caseData?.Category?.workflow !==
                                            "Poshratroubleshootflow" && (
                                            <Popover
                                                content={
                                                    <div>
                                                        <p>
                                                            Draft will be saved
                                                        </p>
                                                    </div>
                                                }
                                                title="Save Draft">
                                                <button
                                                    id="saveDraftCTForm"
                                                    disabled={
                                                        disableResolvingReport
                                                    }
                                                    className={
                                                        disableResolvingReport
                                                            ? styles.disabledBtn
                                                            : "secondaryButton"
                                                    }
                                                    onClick={() => {
                                                        showModalDraft();
                                                        handleCaseClosingDraft();
                                                    }}>
                                                    Save Draft
                                                </button>
                                            </Popover>
                                        )}
                                </Col>
                                <Col>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) < 6 && (
                                        <Popover
                                            content={
                                                <div>
                                                    <p>
                                                        {caseClosingForm.getFieldValue(
                                                            "template"
                                                        ) ===
                                                            "Lack of response" ||
                                                        caseClosingForm.getFieldValue(
                                                            "template"
                                                        ) ===
                                                            "POSH case closure (No response)" ||
                                                        caseClosingForm.getFieldValue(
                                                            "template"
                                                        ) ===
                                                            "POSH case closure" ||
                                                        caseData?.Complainer ===
                                                            null
                                                            ? "Case will be Closed"
                                                            : "Case will be Resolve"}
                                                    </p>
                                                </div>
                                            }
                                            title={
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "Lack of response" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "POSH case closure" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) ===
                                                    "POSH case closure (No response)" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "POSH Lack of response" ||
                                                caseData?.Complainer === null
                                                    ? "Close"
                                                    : "Resolve"
                                            }>
                                            <button
                                                id="submitButtonCT"
                                                disabled={
                                                    disableResolvingReport
                                                }
                                                className={
                                                    disableResolvingReport
                                                        ? styles.disabledBtn
                                                        : "primaryButton"
                                                }
                                                htmltype="submit"
                                                onClick={() => {
                                                    submitHandler();
                                                    handleCaseClosingSubmission();
                                                }}>
                                                {caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "Lack of response" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) ===
                                                    "POSH case closure (No response)" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "POSH case closure" ||
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) === "POSH Lack of response"
                                                    ? "Close the case"
                                                    : "Submit Report"}
                                            </button>
                                        </Popover>
                                    )}
                                </Col>

                                <Col>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) === 6 &&
                                        (user.role === "CT" ||
                                            (user.role === "REGIONAL_ADMIN" &&
                                                (caseData?.Category?.workflow ==
                                                    "Poshratroubleshootflow" ||
                                                    caseData?.Category
                                                        ?.workflow ===
                                                        "Ratroubleshootflow"))) && (
                                            <>
                                                <button
                                                    disabled={
                                                        selectedValue ===
                                                            null &&
                                                        caseData?.Complainer !==
                                                            null
                                                    }
                                                    className={
                                                        selectedValue ===
                                                            null &&
                                                        caseData?.Complainer !==
                                                            null
                                                            ? styles.disabledBtn
                                                            : "secondaryButton"
                                                    }
                                                    // if the case dose not have comploiner we want to call showClosingtheCaseModal which will close the case
                                                    // if the case has Complainer then based on selected value satisfied = "1" or unsatisfied = "2" we are calling reopen or close modal
                                                    onClick={() => {
                                                        caseData?.Complainer !==
                                                        null
                                                            ? selectedValue ===
                                                              "2"
                                                                ? setIsReopenModalVisible(
                                                                      true
                                                                  )
                                                                : showModalResolve()
                                                            : showClosingtheCaseModal();
                                                    }}>
                                                    Submit
                                                </button>
                                            </>
                                        )}
                                </Col>
                            </Row>
                        </Form>
                        {disableResolvingReport && (
                            <div className={styles.overlay}></div>
                        )}
                    </>
                )}
                <ActionModal
                    isOpen={modalStates.showAutomationModal}
                    onCancel={() => handleCancel("showAutomationModal")}
                    title="Please Confirm"
                    content="How would you like to proceed ahead with the case?"
                    actions={actions}
                />
            </div>
        )
    );
}
