import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Form, DatePicker, Divider, Select } from "antd";

import { userState } from "../../../RecoilState/userState";
import CustomButton from "../../Utils/CustomButton";
import { Typography } from "antd";
import { FactoryData } from "../../../RecoilState/FactoryData";
import useFetchRegion from "../../AdminDashboards/RegionDashboard/RegionHooks/FetchRegionHook";
import { downloadData } from "../../../Adapters/TableDataByUser";
import { regionState } from "../../../RecoilState/regionState";
import { factoryState } from "../../../RecoilState/FactoryState";

const { Title } = Typography;

const { RangePicker } = DatePicker;

const ExportDataForm = ({ closeModal }) => {
    const [user] = useRecoilState(userState);
    const [FactoryList] = useRecoilState(FactoryData);
    const [FactorySelected] = useRecoilState(factoryState);
    const { fetchRegionData } = useFetchRegion();
    const [regions] = useRecoilState(regionState);
    const [form] = Form.useForm();
    const selectedRegions = Form.useWatch("region", form);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user.role === "SUPER_ADMIN") {
            fetchRegionData();
        }
    }, []);

    const availableFactories = useMemo(
        () =>
            regions?.reduce((accumulator, item) => {
                if (form.getFieldValue("region")?.includes(item.Name)) {
                    return accumulator.concat(item.Factories);
                }
                return accumulator;
            }, []),
        [selectedRegions]
    );

    useEffect(() => {
        const currentFactories = form.getFieldValue("factory") || [];
        const updatedFactories = currentFactories.filter((factoryCode) => {
            return availableFactories.some(
                (factory) => factory.Code === factoryCode
            );
        });

        form.setFieldsValue({ factory: updatedFactories });
    }, [selectedRegions, availableFactories, form]);

    const options = {
        medium: ["Call", "In Person", "Suggestion Box", "Worker Committee"].map(
            (item) => ({
                label: item,
                value: item,
            })
        ),
        region: regions.map((item) => ({
            label: item.Name,
            value: item.Name,
        })),
        factory:
            regions.length > 0
                ? availableFactories?.map((item) => ({
                      label: item.Code,
                      value: item.Code,
                  }))
                : FactoryList.map((item) => ({
                      label: item.Code,
                      value: item.Code,
                  })),
        grievanceLevel: [
            {
                label: "Major Grievance (Level 1)",
                value: "Major Grievance (Level 1)",
            },
            {
                label: "Major Grievance (Level 2)",
                value: "Major Grievance (Level 2)",
            },
            {
                label: "Medium Grievance (Internal)",
                value: "Medium Grievance (Internal)",
            },
            {
                label: "Medium Grievance (External)",
                value: "Medium Grievance (External)",
            },
            {
                label: "Minor Grievance (Internal)",
                value: "Minor Grievance (Internal)",
            },
            {
                label: "Minor Grievance (External)",
                value: "Minor Grievance (External)",
            },
        ],
        category: [
            "Canteen",
            "Workplace Maintenance",
            "Works and Grievances",
            "Banking and Salary",
            "Special Cases",
            "Others",
        ].map((item) => ({
            label: item,
            value: item,
        })),
    };

    const handleSelectAll = (e, key) => {
        if (e.target.checked) {
            form.setFieldsValue({
                [key]: options[key].map((item) => item.value),
            });
        } else {
            form.setFieldsValue({
                [key]: [],
            });
        }
    };

    const tagRender = (props) => <span>{props.label},&nbsp;</span>;

    const optionRender = (option, key) => {
        const value = form.getFieldValue(key);
        return (
            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "flex-start",
                }}>
                <input
                    type="checkbox"
                    checked={value?.includes(option.value) || false}
                />
                {option.label}
            </div>
        );
    };

    const dropdownRender = (menu, key) => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        padding: "5px 12px",
                        gap: "1rem",
                        justifyContent: "flex-start",
                    }}>
                    <input
                        type="checkbox"
                        checked={
                            form.getFieldValue(key)
                                ? form.getFieldValue(key).length ==
                                  options[key].length
                                : false
                        }
                        onChange={(e) => handleSelectAll(e, key)}
                    />
                    Select All
                </div>
                <Divider
                    style={{
                        marginTop: "6px",
                        marginBottom: "4px",
                    }}
                />
                {menu}
            </>
        );
    };

    const formFields = [
        {
            display: true,
            label: "Date Range",
            name: "date_range",
            required: true,
            type: <RangePicker />,
        },
        {
            display: true,
            label: "Medium/s",
            name: "medium",
            required: true,
            type: (
                <Select
                    maxTagCount="responsive"
                    tagRender={tagRender}
                    data-testid="medium-dropdown"
                    mode="multiple"
                    showSearch={false}
                    options={options.medium}
                    dropdownRender={(menu) => dropdownRender(menu, "medium")}
                    optionRender={(option) => optionRender(option, "medium")}
                />
            ),
        },
        {
            display: user.role === "SUPER_ADMIN",
            label: "Region/s",
            name: "region",
            required: true,
            type: (
                <Select
                    maxTagCount="responsive"
                    tagRender={tagRender}
                    data-testid="region-dropdown"
                    mode="multiple"
                    showSearch={false}
                    options={options.region}
                    dropdownRender={(menu) => dropdownRender(menu, "region")}
                    optionRender={(option) => optionRender(option, "region")}
                />
            ),
        },
        {
            display: ["SUPER_ADMIN", "REGIONAL_ADMIN"].includes(user.role),
            label: "Factory/s",
            name: "factory",
            required: true,
            type: (
                <Select
                    tagRender={tagRender}
                    maxTagCount="responsive"
                    data-testid="factory-dropdown"
                    mode="multiple"
                    showSearch={false}
                    options={options.factory}
                    dropdownRender={(menu) => dropdownRender(menu, "factory")}
                    optionRender={(option) => optionRender(option, "factory")}
                />
            ),
        },
        {
            display: true,
            label: "Grievance Level",
            name: "grievanceLevel",
            type: (
                <Select
                    tagRender={tagRender}
                    maxTagCount="responsive"
                    data-testid="grievance-dropdown"
                    mode="multiple"
                    showSearch={false}
                    options={options.grievanceLevel}
                    dropdownRender={(menu) =>
                        dropdownRender(menu, "grievanceLevel")
                    }
                    optionRender={(option) =>
                        optionRender(option, "grievanceLevel")
                    }
                />
            ),
        },
        {
            display: true,
            label: "Category/s",
            name: "category",
            type: (
                <Select
                    tagRender={tagRender}
                    maxTagCount="responsive"
                    data-testid="category-dropdown"
                    mode="multiple"
                    showSearch={false}
                    options={options.category}
                    dropdownRender={(menu) => dropdownRender(menu, "category")}
                    optionRender={(option) => optionRender(option, "category")}
                />
            ),
        },
    ];

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleSubmit = async () => {
        setLoading(true);
        const date = form
            .getFieldValue("date_range")
            ?.map((item) => item.format("YYYY-MM-DD"));

        try {
            const res = await downloadData(
                date,
                form.getFieldValue("medium"),
                form.getFieldValue("factory")?.length
                    ? form.getFieldValue("factory")
                    : [FactorySelected.Code],
                form.getFieldValue("grievanceLevel"),
                form.getFieldValue("category")
            );
            if (res.headers["content-type"] === "application/zip") {
                const blob = new Blob([res.data], {
                    type: res.headers["content-type"],
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "raw_data_export.zip";
                link.click();
                window.URL.revokeObjectURL(link.href);
            }
            setTimeout(() => {
                form.resetFields();
                closeModal();
            }, 250);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedSubmit = debounce(handleSubmit, 250);

    return (
        <>
            <Title
                style={{
                    textAlign: "left",
                    margin: "1.25rem",
                    marginLeft: "2rem",
                    fontFamily: "Poppins",
                }}
                level={4}>
                Download Raw Data
            </Title>
            <Divider
                style={{
                    borderColor: "#BEBEBE",
                    margin: "0",
                }}
            />
            <Form
                form={form}
                disabled={loading}
                onFinish={debouncedSubmit}
                name="Export Data Form"
                style={{
                    padding: "1rem 1rem 0 1.5rem",
                }}>
                {formFields.map(
                    (item) =>
                        item.display && (
                            <Form.Item
                                key={item.name}
                                style={{
                                    marginBottom: "0.75rem",
                                }}
                                name={item.name}
                                label={item.label}
                                rules={[
                                    item.required && {
                                        required: true,
                                        message: `Please enter ${item.label}`,
                                    },
                                ]}>
                                {item.type}
                            </Form.Item>
                        )
                )}
                <Divider
                    style={{
                        borderColor: "#BEBEBE",
                        margin: "1rem 0",
                    }}
                />
                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            gap: "1rem",
                        }}>
                        <CustomButton
                            text="Cancel"
                            colour="white"
                            loading={loading}
                            onClick={closeModal}
                        />
                        <CustomButton
                            loading={loading}
                            text="Confirm"
                            htmlType="submit"
                        />
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default ExportDataForm;
